import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Tab, Tabs } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
  return {
    tabs: {
      borderColor: 'divider',
      left: 0,
      right: 'auto',
      '.MuiTabs-indicator': {
        backgroundColor: '#fff',
        left: 0,
      },
    },
    tab: {
      minWidth: '48px',
    },
  };
});

export function ActivityBar(props: any) {
  const { leftMenuValue, onLeftMenuChange, onLeftMenuToggle } = props;
  const { classes } = useStyles();

  return (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={leftMenuValue}
      onChange={(event: React.SyntheticEvent, newValue: any) =>
        onLeftMenuChange(event, newValue)
      }
      aria-label="Vertical tabs example"
      className={classes.tabs}
    >
      <Tab
        icon={<FileCopyOutlinedIcon style={{ color: '#fff' }} />}
        onClick={() => onLeftMenuToggle('file')}
        aria-label="file"
        value="file"
        className={classes.tab}
      />
    </Tabs>
  );
}

export default ActivityBar;
