import { SvgIcon } from '@mui/material';
import React from 'react';
import { IconographyProps } from './iconography.interface';

const InstructorColorIcon = ({
  primaryColor,
  secondaryColor,
  sx,
  ...props
}: IconographyProps) => {
  const primaryDefault = '#6B7580';
  const secondaryDefault = '#999999';
  return (
    <SvgIcon
      sx={{ fill: 'none', width: 51, height: 51, ...sx }}
      {...props}
      viewBox="0 0 51 51"
    >
      <path
        d="M30.8264 6H41.1736C41.6327 6 42 6.53066 42 7.17146V12.8285C42 13.4793 41.6257 14 41.1736 14H30.8264C30.3673 14 30 13.4693 30 12.8285V7.17146C30 6.52065 30.3673 6 30.8264 6Z"
        fill="#A9D8C6"
      />
      <path
        opacity="0.5"
        d="M12.0601 45.1802C16.4783 45.1802 20.0601 41.5985 20.0601 37.1802C20.0601 32.7619 16.4783 29.1802 12.0601 29.1802C7.64178 29.1802 4.06006 32.7619 4.06006 37.1802C4.06006 41.5985 7.64178 45.1802 12.0601 45.1802Z"
        fill="#FFEBBA"
      />
      <path
        d="M26.7401 22.1901C30.2401 22.2001 33.1301 19.3101 33.1301 15.8101C33.1401 12.2401 30.3001 9.37014 26.7601 9.37014C23.2201 9.36014 20.3301 12.2301 20.3301 15.7701C20.3201 19.2901 23.2101 22.1801 26.7401 22.1901Z"
        stroke="#054C72"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.59 37.3402C38.62 33.9102 38.62 33.1402 38.58 29.7102C38.57 28.6702 38.26 27.7302 37.73 26.9802C36.89 25.8002 35.51 25.0602 33.87 25.0602C29.12 25.0502 24.37 25.0502 19.62 25.0602C18.06 25.0602 16.72 25.7502 15.87 26.8502C15.29 27.6102 14.95 28.5602 14.93 29.6302C14.88 33.1302 14.89 33.9702 14.92 37.4802"
        stroke="#054C72"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.95 35.5503V38.3703"
        stroke="#054C72"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5901 35.5503V38.3703"
        stroke="#054C72"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default InstructorColorIcon;
