import { SvgIcon } from '@mui/material';
import React from 'react';
import { IconographyProps } from './iconography.interface';

const DurationColorIcon = ({
  primaryColor,
  secondaryColor,
  sx,
  ...props
}: IconographyProps) => {
  const primaryDefault = '#6B7580';
  const secondaryDefault = '#999999';
  return (
    <SvgIcon
      sx={{ fill: 'none', width: 51, height: 51, ...sx }}
      {...props}
      viewBox="0 0 51 51"
    >
      <path
        d="M10.4499 36.75H15.0399C15.3599 36.75 15.6099 37.01 15.6099 37.32V41.91C15.6099 42.23 15.3499 42.48 15.0399 42.48H10.4499C10.1299 42.48 9.87988 42.22 9.87988 41.91V37.32C9.87988 37 10.1399 36.75 10.4499 36.75Z"
        fill="#A9D8C6"
      />
      <path
        opacity="0.5"
        d="M23.7 24.13C22.51 24.13 21.8 22.74 22.45 21.69L31.28 7.62004C31.87 6.68004 33.18 6.68004 33.77 7.62004L42.6 21.69C43.26 22.73 42.54 24.13 41.35 24.13H23.7Z"
        fill="#FFEBBA"
      />
      <path
        d="M23.78 41.1299C30.9597 41.1299 36.78 35.3096 36.78 28.1299C36.78 20.9502 30.9597 15.1299 23.78 15.1299C16.6003 15.1299 10.78 20.9502 10.78 28.1299C10.78 35.3096 16.6003 41.1299 23.78 41.1299Z"
        stroke="#054C72"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.8799 15.1299V11.1299"
        stroke="#054C72"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8799 11.1299H25.8799"
        stroke="#054C72"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.02 16.7299L31.98 14.1299"
        stroke="#054C72"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.78 19.1299V28.1299"
        stroke="#054C72"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default DurationColorIcon;
