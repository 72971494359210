import { Box, Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';

import { getSocketState, sendMessage } from '../socket/socket.slice';
import ActivityBar from './activity-bar/activity';
import Browser from './browser/browser';
import {
  selectConfigs,
  selectEditorOpenFiles,
  selectTerminalTabs,
  setEditorFile,
} from './editor.slice';
import { FileTree } from './explorer';
import MonacoEditor from './monaco-editor/editor';
import Terminal from './terminal/terminal';

export interface CodeEditorProps {
  handleEditorChange: any;
  filePath?: string;
}

function CodeEditor(props: CodeEditorProps) {
  const { filePath } = props;
  const nodeRef = useRef(null);
  const node2Ref = useRef(null);
  const nodePreviewRef = useRef(null);
  const editorRef = useRef(null);
  const parentRef = useRef(null);
  const previewRef = useRef(null);
  const contentChangedTimerRef = useRef(null);

  const dispatch = useDispatch();
  const socketState = useSelector(getSocketState);
  const filesOpened = useSelector(selectEditorOpenFiles);
  const terminalTabs = useSelector(selectTerminalTabs);
  const configs = useSelector(selectConfigs);
  const [leftMenuValue, setLeftMenuValue] = useState('file');
  const [leftMenuActive, setLeftMenuActive] = useState('file');

  const [showPreview, setShowPreview] = useState(false);
  // File Tree
  const [leftPanelWidth, setLeftPanelWidth] = useState(310);
  const [leftPanelPrevWidth, setLeftPanelPrevWidth] = useState(310);
  const [centerPanelWidth, setCenterPanelWidth] = useState(0);
  const [rightPanelWidth, setRightPanelWidth] = useState(500);
  // File Tree

  // Terminal
  const [bottomPanelHeight, setBottomPanelHeight] = useState(0);
  const [topPanelHeight, setTopPanelheight] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  // Terminal

  // save file
  const [triggerSaveFile, setTriggerSaveFile] = useState(null);
  // save file

  useEffect(() => {
    if (socketState.isConnected) {
      dispatch(
        sendMessage({
          handler: 'GET_FILES',
          message: { path: filePath },
        })
      );
      // dispatch(
      //   sendMessage({
      //     handler: 'WATCH_FOR_CHANGES',
      //     message: { path: filePath },
      //   })
      // );
    }
  }, [dispatch, filePath, socketState.isConnected]);

  const dragging = (event: any, data: { deltaX: number }) => {
    const { deltaX } = data;
    if (deltaX) {
      setLeftPanelWidth(leftPanelWidth + deltaX);
    }
  };

  const draggingPreview = (event: any, data: { deltaX: number }) => {
    const { deltaX } = data;
    if (deltaX) {
      setCenterPanelWidth(
        parentRef &&
          parentRef.current.clientWidth -
            leftPanelWidth -
            50 -
            (rightPanelWidth - deltaX)
      );
      setRightPanelWidth(rightPanelWidth - deltaX);
    }
  };

  const dragging2 = (event: any, data: { deltaY: number; lastY: number }) => {
    const { deltaY } = data;
    if (deltaY) {
      setBottomPanelHeight(bottomPanelHeight - deltaY);
    }
  };

  const onLeftMenuChange = (event: React.SyntheticEvent, newValue: string) => {
    setLeftMenuValue(newValue);
  };

  const onLeftMenuToggle = (value: string) => {
    if (value === leftMenuActive) {
      setLeftMenuActive('');
      setLeftPanelPrevWidth(leftPanelWidth);
      setCenterPanelWidth(
        parentRef && parentRef.current.clientWidth - 50 - rightPanelWidth
      );
      setLeftPanelWidth(0);
    } else {
      setLeftMenuActive(value);
      setCenterPanelWidth(
        parentRef &&
          parentRef.current.clientWidth -
            (leftPanelPrevWidth > 210 ? leftPanelPrevWidth : 220) -
            50 -
            rightPanelWidth
      );
      setLeftPanelWidth(leftPanelPrevWidth > 210 ? leftPanelPrevWidth : 220);
    }
  };

  const debouncedOnChange = (editedFile) => {
    const { id } = editedFile;
    setTriggerSaveFile(id);
  };

  const handleEditorChange = async (latestValue, activeFile) => {
    dispatch(
      setEditorFile({
        ...activeFile,
        handle: 'CONTENT',
        handleValue: latestValue,
      })
    );

    const isEditedFileIndex =
      filesOpened &&
      filesOpened.findIndex(
        (file) => file.id === activeFile.id && !activeFile.isDirty
      );
    setTriggerSaveFile(null);
    clearTimeout(contentChangedTimerRef.current);

    if (isEditedFileIndex > -1) {
      dispatch(
        setEditorFile({ ...activeFile, handle: 'IS_DIRTY', handleValue: true })
      );
    }
    contentChangedTimerRef.current = setTimeout(() => {
      debouncedOnChange(activeFile);
    }, 1500);
  };

  const delay = (milliseconds) =>
    new Promise((resolve) => {
      setTimeout(resolve, milliseconds);
    });

  useEffect(() => {
    if (triggerSaveFile) {
      const editedFileData = filesOpened.find(
        (file) => file.id === triggerSaveFile
      );
      dispatch(
        sendMessage({
          handler: 'UPDATE_FILE',
          message: { ...editedFileData },
        })
      );
    }
  }, [triggerSaveFile]);

  // useEffect(() => {
  //   if (showPreview) {
  //     setRightPanelWidth(200);
  //     setCenterPanelWidth(
  //       parentRef &&
  //         parentRef.current.clientWidth - leftPanelWidth - 50 - rightPanelWidth
  //     );
  //   } else {
  //     setRightPanelWidth(0);
  //     setCenterPanelWidth(
  //       parentRef && parentRef.current.clientWidth - leftPanelWidth - 50
  //     );
  //   }
  // }, [showPreview]);

  useEffect(() => {
    const { showTerminals = false, preview, type } = configs;

    if (!showTerminals) {
      setBottomPanelHeight(0);
    }

    if (preview && type !== 'hands-on') {
      setShowPreview(true);
    }
  }, [configs]);

  useEffect(() => {
    setCenterPanelWidth(
      parentRef &&
        parentRef.current.clientWidth -
          leftPanelWidth -
          50 -
          (showPreview ? rightPanelWidth : 0)
    );
  }, [leftPanelWidth]);

  useEffect(() => {
    setTopPanelheight(
      editorRef.current && editorRef.current.clientHeight - bottomPanelHeight
    );
  }, [bottomPanelHeight]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setTopPanelheight(
        editorRef.current && editorRef.current.clientHeight - bottomPanelHeight
      );
    });
    const handleKeyDown = (event) => {
      // Check if Ctrl + S is pressed
      if (event.ctrlKey && event.keyCode === 83) {
        event.preventDefault(); // Prevent default browser save action
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      clearTimeout(contentChangedTimerRef.current);
      window.removeEventListener('resize', () => {
        setTopPanelheight(
          editorRef.current &&
            editorRef.current.clientHeight - bottomPanelHeight
        );
      });
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const { showTerminals = false } = configs;
    if (!terminalTabs || terminalTabs.length <= 0) {
      setBottomPanelHeight(0);
    } else {
      setBottomPanelHeight(showTerminals ? 200 : 0);
    }
  }, [terminalTabs]);

  return (
    <Stack
      direction="row"
      sx={{ bgcolor: '#1e1e1e' }}
      height="100%"
      width="100%"
      overflow={'hidden'}
      ref={parentRef}
      display={'flex'}
    >
      <Stack
        sx={{
          backgroundColor: '#403f3f',
          width: '-webkit-fill-available',
          m: 0,
          maxWidth: '45px',
        }}
      >
        <ActivityBar
          leftMenuValue={leftMenuValue}
          onLeftMenuChange={onLeftMenuChange}
          onLeftMenuToggle={onLeftMenuToggle}
        ></ActivityBar>
      </Stack>
      <Stack direction="row" marginRight={0} id={'drag-left'}>
        <Box
          sx={{
            width: leftPanelWidth,

            bgcolor: '#303030',
            m: 0,
            margin: '0 !important',
            boxShadow:
              '0px 2px 4px rgba(0, 1, 2, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <FileTree key="file-tree" rootPath={filePath}></FileTree>
        </Box>
        <Draggable
          axis="x"
          nodeRef={nodeRef}
          onDrag={(event: any, data: any) => dragging(event, data)}
          bounds={{ left: -100, right: 500 }}
        >
          <Box
            ref={nodeRef}
            sx={{
              height: '-webkit-fill-available',
              width: '5px',
              backgroundColor: 'transparent',
              transform: 'translate(0px, 0px) !important',
              '&:hover': {
                cursor: 'col-resize',
              },
            }}
          >
            {' '}
          </Box>
        </Draggable>
      </Stack>

      <Stack
        sx={{
          backgroundColor: '#1e1e1e',
          height: '100%',
          m: 0,
          position: 'relative',
          // display: 'flex',
          // flexDirection: 'column',
          // flexGrow: 1,
        }}
        width={centerPanelWidth}
        ref={editorRef}
      >
        <Box height={topPanelHeight} sx={{ overflow: 'hidden' }}>
          <MonacoEditor
            width={centerPanelWidth}
            height={topPanelHeight}
            handleEditorChange={handleEditorChange}
          ></MonacoEditor>
        </Box>

        <Box id={'drag-bottom'}>
          <Draggable
            axis="y"
            nodeRef={node2Ref}
            onDrag={(event: any, data: any) => dragging2(event, data)}
            bounds={{ bottom: 100, top: -400 }}
          >
            <Box
              ref={node2Ref}
              sx={{
                height: '5px',
                width: '-webkit-fill-available',
                backgroundColor: '#252526',
                transform: 'translate(0px, 0px) !important',
                '&:hover': {
                  cursor: 'row-resize',
                },
              }}
            >
              {' '}
            </Box>
          </Draggable>

          <Box height={bottomPanelHeight}>
            <Terminal terminalHeight={bottomPanelHeight}></Terminal>
          </Box>
        </Box>
        {showPreview && (
          <Draggable
            axis="x"
            nodeRef={nodePreviewRef}
            onDrag={(event: any, data: any) => draggingPreview(event, data)}
            onStart={() => setIsDragging(true)}
            onStop={() => setIsDragging(false)}
            // bounds={{ left: 500, right: -100 }}
          >
            <Box
              ref={nodePreviewRef}
              sx={{
                height: '-webkit-fill-available',
                width: '5px',
                backgroundColor: 'transparent',
                transform: 'translate(0px, 0px) !important',
                position: 'absolute',
                right: 0,
                zIndex: 999,
                '&:hover': {
                  cursor: 'col-resize',
                },
              }}
            >
              {' '}
            </Box>
          </Draggable>
        )}
      </Stack>
      {showPreview && (
        <Stack
          ref={previewRef}
          width={rightPanelWidth}
          position={'absolute'}
          top={0}
          bottom={0}
          right={0}
          overflow={'hidden'}
          sx={{ pointerEvents: isDragging ? 'none' : '' }}
        >
          <Browser></Browser>
        </Stack>
      )}
    </Stack>
  );
}

export default CodeEditor;
