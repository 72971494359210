import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISocketState } from './interface';

export const SOCKET = 'socket';

const initialSocketState: ISocketState = {
    loaded: false,
    isLoading: false,
    isLoaded: false,
    isValidate: false,
    error: null,
    isConnected: false,
    messages: [],
    socket: null
};

export const socketSlice = createSlice({
    name: SOCKET,
    initialState: initialSocketState as ISocketState,
    reducers: {
        connectSocket: (state) => {
            state.isConnected = false;
        },
        connectSocketSuccess: (state, action) => {
            state.isConnected = true;
            state.socket = action?.payload;

        },
        disconnectSocket: (state) => {
            state.isConnected = false;
            state.socket = null;
        },
        addMessage: (state, action: any) => {
            state.messages.push(action.payload);
        },
        sendMessage: (state, action) => {

            // Additional reducer logic for sending messages, if needed
        },
        sendMessageSuccess: (state) => {

            // Additional reducer logic for successful message sending, if needed
        },
        sendMessageFailure: (state, action) => {
            // Additional reducer logic for failed message sending, if needed
        },
    },
});

export const socketReducer = socketSlice.reducer;

export const { connectSocket, disconnectSocket, addMessage, sendMessage,
    sendMessageSuccess,
    sendMessageFailure, connectSocketSuccess } =
    socketSlice.actions;

export const getSocketState = (
    rootState: any
): ISocketState => rootState[SOCKET];