import { SvgIcon } from '@mui/material';
import React from 'react';
import { IconographyProps } from './iconography.interface';

const BookColorIcon = ({
  primaryColor,
  secondaryColor,
  sx,
  ...props
}: IconographyProps) => {
  const primaryDefault = '#6B7580';
  const secondaryDefault = '#999999';
  return (
    <SvgIcon
      sx={{ fill: 'none', width: 51, height: 51, ...sx }}
      {...props}
      viewBox="0 0 51 51"
    >
      <rect width="51" height="51" fill="white" />
      <path
        opacity="0.5"
        d="M25.8529 24.1608C24.6154 24.1608 23.8749 22.7126 24.5568 21.6262L33.7505 6.97873C34.3644 6.00058 35.7286 6.00058 36.3426 6.97872L45.5362 21.6262C46.2182 22.7126 45.4776 24.1608 44.2402 24.1608L25.8529 24.1608Z"
        fill="#FFEBBA"
      />
      <rect
        x="8.8269"
        y="33.3462"
        width="9.80769"
        height="9.80769"
        rx="0.57377"
        fill="#A9D8C6"
      />
      <path
        d="M35.6346 12.75H15.6106C14.0307 12.75 12.75 14.0307 12.75 15.6106V35.6346C12.75 37.2145 14.0307 38.4952 15.6106 38.4952H35.6346C37.2145 38.4952 38.4952 37.2145 38.4952 35.6346V15.6106C38.4952 14.0307 37.2145 12.75 35.6346 12.75Z"
        stroke="#054C72"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3845 18.6348H18.6345V22.5578H31.3845V18.6348Z"
        stroke="#054C72"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.3462 28.4424H28.4424V32.3655H33.3462V28.4424Z"
        stroke="#054C72"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default BookColorIcon;
