import React from 'react';
import MuiAccordion, { accordionClasses } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails, {
  accordionDetailsClasses,
} from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

import { IAccordionProps } from './accordion.interface';
import { ExpandMoreIcon } from '../iconography';

const StyledMuiAccordion = styled(MuiAccordion)(({ theme }) => ({
  [`&.${accordionClasses.root}`]: {
    ...theme.prism.accordion.root,
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  ...theme.prism.accordion.title,
}));

const StyledDescription = styled(Typography)(({ theme }) => ({
  ...theme.prism.accordion.description,
}));

const StyledMuiAccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  [`&.${accordionSummaryClasses.root}`]: {
    padding: theme.spacing(0, 8),
    [`& .${accordionSummaryClasses.content}`]: {
      margin: theme.spacing(0, 0),
    },
    [`& .${accordionSummaryClasses.content}.${accordionClasses.expanded}`]: {
      marginTop: theme.spacing(7 / 2),
      marginBottom: theme.spacing(9 / 2),
    },
  },
}));

const StyledMuiEmptyAccordionSummary = styled(MuiAccordionSummary)(
  ({ theme }) => ({
    [`&.${accordionSummaryClasses.root}`]: {
      minHeight: 0,
      height: 0,
    },
  })
);

const StyledMuiAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  [`&.${accordionDetailsClasses.root}`]: {
    padding: theme.spacing(0, 0, 0),
  },
}));

export default function Accordion(props: IAccordionProps) {
  const {
    id,
    title,
    summary,
    description,
    children,
    expandedStatusOrId,
    onToggleChange,
    showIcon = true,
    hideSummary = false,
    ...rest
  } = props;

  const renderHeader = () => {
    if (summary) return summary;

    return <StyledTitle>{title}</StyledTitle>;
  };

  return (
    <StyledMuiAccordion
      {...rest}
      expanded={Boolean(expandedStatusOrId === id)}
      onChange={(e, expanded) => {
        onToggleChange(id, expanded);
      }}
      key={id}
    >
      {hideSummary ? (
        <StyledMuiEmptyAccordionSummary></StyledMuiEmptyAccordionSummary>
      ) : (
        <StyledMuiAccordionSummary expandIcon={<ExpandMoreIcon />}>
          {renderHeader()}
        </StyledMuiAccordionSummary>
      )}
      <StyledMuiAccordionDetails>
        <Box
          sx={(theme) => ({
            px: theme.spacing(8),
          })}
        >
          {description && typeof description === 'string' ? (
            <>
              <StyledDescription>{description}</StyledDescription>
            </>
          ) : (
            description
          )}
          <Divider sx={{ my: (theme) => theme.spacing(3) }} />
        </Box>
        <Box>{children}</Box>
      </StyledMuiAccordionDetails>
    </StyledMuiAccordion>
  );
}
