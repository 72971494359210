import { Box, Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import {
  selectActiveFile,
  selectConfigs,
  selectEditorOpenFiles,
  selectTerminalTabs,
  setActiveFile,
  setEditorOpenFiles,
} from '../../editor.slice';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import CloudTypography from 'libs/common/src/lib/components/core/cloud-typography/cloud-typography';

type IFile<T = unknown> = {
  id: number | string;
  parent: number | string;
  text: string;
  value?: string;
  isSet?: boolean;
  isDirty?: boolean;
};

const useStyles = makeStyles()(() => {
  return {
    topBar: {
      backgroundColor: '#252526',
      height: '32px',
      overflow: 'auto',
      overflowY: 'hidden',
      width: 'auto',
      '&::-webkit-scrollbar-track': {
        boxShadow: '0 none',
      },
      '&::-webkit-scrollbar': {
        scrollbarColor: '#252526',
        height: '5px',
        display: 'none',
      },
      '&:hover': {
        '&::-webkit-scrollbar': {
          height: '5px',
          display: 'block',
        },
      },
    },
    topBarTabs: {
      padding: '0 5px 0 20px',
      height: 32,
      borderRadius: 0,
      color: '#fff',
      opacity: '0.7',
      fontSize: '13px',
      fontWeight: 400,
      borderLeft: '0 none',
      borderRight: '1px solid #2d2d2d',
      borderTop: '0 none',
      borderBottom: ' 0 none',
      backgroundColor: '#2d2d2d',
      textTransform: 'inherit',
      '& .close': {
        opacity: 0,
        marginLeft: '5px',
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.1)',
          borderRadius: 2,
        },
      },
      '&.Mui-selected': {
        backgroundColor: '#1e1e1e',
        color: '#fff',
        opacity: 1,
        '& .close': {
          opacity: 1,
        },
      },
      '&.Mui-selected:hover': {
        backgroundColor: '#1e1e1e',
        color: '#fff',
        opacity: 1,
        '& .close': {
          opacity: 1,
        },
        '& .dirty': {
          opacity: 0,
        },
      },
      '&:hover': {
        '& .close': {
          opacity: 1,
        },
        '& .dirty': {
          opacity: 0,
        },
      },
    },
    tabRight: {
      position: 'relative',
      height: 20,
      width: 25,
      marginRight: 5,
      '&:hover': {},
    },
    closeIcon: {
      position: 'absolute',
      height: '100%',
      width: 15,
      zIndex: 999,
      color: '#fff',
    },
    dirtyIcon: {
      position: 'absolute',
      height: '100%',
      width: 12,
      marginRight: 2,
      zIndex: 999,
    },
  };
});

export function FileTabs(props: any) {
  const { activeFile } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const files = useSelector(selectEditorOpenFiles);
  const configs = useSelector(selectConfigs);
  const terminalTabs = useSelector(selectTerminalTabs);

  const handleClick = (event: any, selectedFile: IFile) => {
    dispatch(setActiveFile({ file: selectedFile }));
  };

  const handleCloseFile = (selectedFile) => {
    console.log('Inner element clicked');
    const oldFiles = files;
    const filteredFiles = files.filter((e) => e.id !== selectedFile.id);
    dispatch(setEditorOpenFiles({ files: filteredFiles }));
    const seletedIndex = oldFiles.findIndex((e) => e.id === selectedFile.id);

    if (oldFiles.length > 1 && selectedFile.id === activeFile.id) {
      if (oldFiles.length > seletedIndex + 1) {
        const newSelectedFile = oldFiles[seletedIndex + 1];
        dispatch(setActiveFile({ file: newSelectedFile }));
      } else {
        const newSelectedFile = oldFiles[seletedIndex - 1];
        dispatch(setActiveFile({ file: newSelectedFile }));
      }
    }
  };

  const handleRun = () => {
    console.log(terminalTabs);
    let terminalCommand = {} as { commands: []; index: number };
    Object.keys(terminalTabs).forEach(async (term, index) => {
      if (
        !terminalTabs[term][0].name.toLowerCase().includes('end') &&
        !terminalTabs[term][0].name.toLowerCase().includes('expected')
      ) {
        const commandsArrayObj = terminalTabs[term].find(
          (obj: { commands: [] }) => obj.commands
        );
        const commandsArray =
          (commandsArrayObj && commandsArrayObj.commands) || [];
        terminalCommand = {
          commands: commandsArray,
          index: index + 1,
        };
        const { commands, index: index1 } = terminalCommand;
        // const terminal = (await this.terminalService.getById(
        //   `terminal${index1}`
        // )) as TerminalWidget;
        // terminal?.clearOutput();
        // this.terminalService.open(terminal);
        // await terminal.sendText(`\u0003\n`);
        // await terminal.sendText(`${commands.join(" && ")}\n`);
      }
    });
  };

  useEffect(() => {
    const {
      filesToOpen = [],
      openFiles = [],
      rootPath,
      preview,
      type,
      enableRun,
    } = configs;
    if (filesToOpen.length > 0 || openFiles.length > 0) {
      dispatch(
        setEditorOpenFiles({
          files: filesToOpen || openFiles,
          rootPath,
          isDefault: true,
        })
      );
    }
  }, [configs]);

  return (
    <Stack
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'space-between'}
    >
      <Box className={classes.topBar}>
        <ToggleButtonGroup
          color="primary"
          exclusive
          onChange={handleClick}
          aria-label="Platform"
        >
          {files &&
            files.map((file: IFile, i) => (
              <ToggleButton
                className={classes.topBarTabs}
                value={file}
                selected={activeFile && file.id === activeFile.id}
                key={`file-tabs-${i}`}
              >
                <CloudTypography textVariant="h10">{file.text}</CloudTypography>
                <Box
                  display={'flex'}
                  justifyContent={'flex-end'}
                  className={classes.tabRight}
                  onClick={() => handleCloseFile(file)}
                >
                  {file?.isDirty && (
                    <CircleIcon
                      className={`${classes.dirtyIcon} dirty`}
                    ></CircleIcon>
                  )}
                  <CloseOutlinedIcon
                    className={`${classes.closeIcon} close`}
                  ></CloseOutlinedIcon>
                </Box>
              </ToggleButton>
            ))}
        </ToggleButtonGroup>
      </Box>
      <Box paddingLeft={5}>
        {configs?.enableRun && (
          <Button onClick={handleRun}>
            <CloudTypography
              textVariant="h11"
              color={'#fff'}
              textTransform={'capitalize'}
            >
              Run Code
            </CloudTypography>
          </Button>
        )}
        {configs?.showPreview && (
          <Button>
            <CloudTypography
              textVariant="h11"
              color={'#fff'}
              textTransform={'capitalize'}
            >
              Preview
            </CloudTypography>
          </Button>
        )}
      </Box>
    </Stack>
  );
}

export default FileTabs;
