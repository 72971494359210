// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import apollo from '@cloudlabs-frontend/common/utils/apollo';
import { all, put, takeLatest } from 'redux-saga/effects';

import {
  MUTATION_START_CLOUD_USER_LAB_ASSET,
  MUTATION_STOP_CLOUD_USER_LAB_ASSET,
  QUERY_GET_CLOUD_USER_LAB_ASSET,
  MUTATION_VALIDATE_CLOUD_USER_LAB_ASSET,
  QUERY_GET_PREVIEW_LAB_ASSET,
} from './gql';
import {
  getCloudUserLabAsset,
  getCloudUserLabAssetFailure,
  getCloudUserLabAssetSuccess,
  getPreviewUserLabAsset,
  getValidateAssetResponse,
  startCloudUserLabAsset,
  stopCloudUserLabAsset,
  validateCloudUserLabAsset,
} from './slice';

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
  errors?: any;
}

function* onGetCloudLabAssetList(action: any) {
  try {
    const { where } = action.payload;
    const response: ResponseGenerator = yield apollo.query({
      variables: {
        where,
      },
      query: QUERY_GET_CLOUD_USER_LAB_ASSET,
    });
    if (!response.errors && response?.data?.cloudUserLabAsset) {
      yield put(
        getCloudUserLabAssetSuccess({
          cloudUserLabAsset: response.data.cloudUserLabAsset,
        })
      );
    } else throw response?.errors ?? new Error('Something went wrong');
  } catch (err) {
    yield put(getCloudUserLabAssetFailure(Error('s')));
  }
}

function* onPreviewUserLabAsset(action: any) {
  try {
    const { where } = action.payload;
    const response: ResponseGenerator = yield apollo.query({
      variables: {
        ...where,
      },
      query: QUERY_GET_PREVIEW_LAB_ASSET,
      context: { client: 'preview' },
    });
    if (!response.errors && response?.data?.cloudQuestion) {
      yield put(
        getCloudUserLabAssetSuccess({
          cloudUserLabAsset: {
            ...response.data.cloudQuestion,
            attemptsLeft: response.data.cloudQuestion.resubmissionTimes,
            attempts: response.data.cloudQuestion.resubmissionTimes,
            name: response.data.cloudQuestion.title,
            cloudLabAsset: {
              ...response.data.cloudQuestion,
              name: response.data.cloudQuestion.title,
            },
          },
        })
      );
    } else throw response?.errors ?? new Error('Something went wrong');
  } catch (err) {
    yield put(getCloudUserLabAssetFailure(Error('s')));
  }
}

function* onStartCloudUserLabAsset(action: any) {
  try {
    const { where } = action.payload;
    const response: ResponseGenerator = yield apollo.query({
      variables: {
        where,
      },
      query: MUTATION_START_CLOUD_USER_LAB_ASSET,
    });

    if (!response.errors && !response?.data?.startCloudUserLabAsset) {
      window.location.reload();
    }
    if (!response.errors && response?.data?.startCloudUserLabAsset) {
      yield put(
        getCloudUserLabAssetSuccess({
          cloudUserLabAsset: response.data.startCloudUserLabAsset,
        })
      );
    } else throw response?.errors ?? new Error('Something went wrong');
  } catch (err) {
    yield put(getCloudUserLabAssetFailure(Error('s')));
  }
}

function* onStopCloudUserLabAsset(action: any) {
  try {
    const { where } = action.payload;
    const response: ResponseGenerator = yield apollo.query({
      variables: {
        where,
      },
      query: MUTATION_STOP_CLOUD_USER_LAB_ASSET,
    });
    if (!response.errors && response?.data?.stopCloudUserLabAsset) {
      yield put(
        getCloudUserLabAssetSuccess({
          cloudUserLabAsset: response.data.stopCloudUserLabAsset,
        })
      );
    } else throw response?.errors ?? new Error('Something went wrong');
  } catch (err) {
    yield put(getCloudUserLabAssetFailure(Error('s')));
  }
}

function* onValidateCloudUserLabAsset(action: any) {
  try {
    const { where } = action.payload;
    const response: ResponseGenerator = yield apollo.query({
      variables: {
        where,
      },
      query: MUTATION_VALIDATE_CLOUD_USER_LAB_ASSET,
    });
    if (!response.errors && response?.data?.validateCloudUserLabAsset) {
      yield put(
        getValidateAssetResponse({
          testCases: response.data.validateCloudUserLabAsset.testCases,
        })
      );
    } else throw response?.errors ?? new Error('Something went wrong');
  } catch (err) {
    console.log(err);
    yield put(getCloudUserLabAssetFailure(Error('s')));
  }
}

export function* cloudLabAssetSagaWatcher() {
  yield all([
    takeLatest(getCloudUserLabAsset.type, onGetCloudLabAssetList),
    takeLatest(startCloudUserLabAsset.type, onStartCloudUserLabAsset),
    takeLatest(stopCloudUserLabAsset.type, onStopCloudUserLabAsset),
    takeLatest(validateCloudUserLabAsset.type, onValidateCloudUserLabAsset),
    takeLatest(getPreviewUserLabAsset.type, onPreviewUserLabAsset),
  ]);
}
