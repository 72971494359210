// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { all, put } from 'redux-saga/effects';

import { getEditorFiles, onGetFilesSuccess } from './editor.slice';


export interface ResponseGenerator {
    config?: any,
    data?: any,
    headers?: any,
    request?: any,
    status?: number,
    statusText?: string
    errors?: any
}

function* onGetFiles(action: any) {
    yield put(
        onGetFilesSuccess({
            files: [],
        })
    );
}

export function* editorSagaWatcher() {
    yield all([getEditorFiles.type, onGetFiles]);
}


