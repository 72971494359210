import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEditorState } from './interface';

export const GET_EDITOR = 'editor';

const initialGetEditorState: IEditorState = {
    loaded: false,
    files: [],
    openFiles: [],
    activeFile: {},
    isLoading: false,
    isLoaded: false,
    isValidate: false,
    error: null,
    leftMenuActive: '',
    terminalTabs: [],
    terminals: [],
    configs: {}
};

const joinPaths = (...paths) => {
    return paths
        .join('/')
        .replace(/\/{2,}/g, '/')
        .replace(/\/$/, '');
};

export const editorSlice = createSlice({
    name: GET_EDITOR,
    initialState: initialGetEditorState as IEditorState,
    reducers: {
        getEditorFiles: (
            state,
            action: PayloadAction<
                any
            >
        ) => {
            state.isLoading = true;
            state.isLoaded = true;
        },
        onGetFilesSuccess: (
            state,
            action: PayloadAction<
                any
            >
        ) => {
            const { isUpdate = false } = action.payload;
            if (isUpdate) {
                state.files = [...state.files, ...action.payload.files];
            } else {
                state.files = action.payload.files;
            }
        },
        onAddFileSuccess: (
            state,
            action: PayloadAction<
                any
            >
        ) => {
            state.files = [...state.files, action.payload.file];
        },
        onMoveFileOrFolderSuccess: (
            state,
            action: PayloadAction<
                any
            >
        ) => {
            const { from: source, to: destination } = action.payload.file;
            let updatedList = state.files.filter(file => file.id !== source.id)
            if (destination?.type === "DIRECTORY") {
                updatedList = updatedList.map(file => file.id.includes(source?.id) ? { ...file, id: file.id.replace(source?.id, destination?.id), parent: file.parent.replace(source?.parent, destination.parent) } : file)

                const { files = [] } = destination
                state.files = [...updatedList, ...files];
                return
            }

            state.files = [...updatedList, destination];
        }, onCopyFileOrFolderSuccess: (
            state,
            action: PayloadAction<
                any
            >
        ) => {
            const { to: destination } = action.payload.file;

            if (destination?.type === "DIRECTORY") {
                state.files = [...state.files, ...destination.files];
            } else { state.files = [...state.files, destination]; }

        },
        onDeleteSuccess: (
            state,
            action: PayloadAction<
                any
            >
        ) => {
            state.files = state.files.filter(file => file.id !== action.payload.file.id);

            const isFileOpen = state.openFiles.findIndex(e => e.id === action.payload.file.id);

            if (isFileOpen > -1) {
                state.openFiles = state.openFiles.filter(file => file.id !== action.payload.file.id);

                if (isFileOpen > 0) {
                    const checkIfExist = state.openFiles[isFileOpen]?.id
                    state.openFiles[checkIfExist ? isFileOpen : isFileOpen - 1].isActive = true
                } else if (isFileOpen < state.openFiles.length - 1) {
                    state.openFiles[isFileOpen + 1].isActive = true
                }
            }
        },
        setEditorOpenFiles: (
            state,
            action: PayloadAction<
                any
            >
        ) => {

            const { isDefault = false, files, rootPath = null } = action.payload;

            if (isDefault) {

                const initialOpenFiles = state.files.filter(file => files.findIndex(e => file.id.includes(e)) >= 0);

                initialOpenFiles[0].isActive = true

                state.openFiles = initialOpenFiles;

            } else {
                state.openFiles = action.payload.files;
            }
        },
        setConfigs: (
            state,
            action: PayloadAction<
                any
            >
        ) => {

            state.configs = action.payload.configs;
        },
        setEditorFile: (
            state,
            action: PayloadAction<
                any
            >
        ) => {
            const { id, value, handle, handleValue } = action.payload;

            switch (handle) {
                case 'IS_DIRTY':
                    {

                        const updatedFiles = state.openFiles.map((file, index) => {
                            if (file.id === id) {
                                return { ...file, isDirty: handleValue };
                            }
                            return file;
                        });
                        state.openFiles = updatedFiles;
                    }
                    break;
                case 'CONTENT':
                    {

                        const updatedFiles = state.openFiles.map((file, index) => {
                            if (file.id === id) {
                                return { ...file, value: handleValue };
                            }
                            return file;
                        });
                        state.openFiles = updatedFiles;
                    }
                    break
                default:
                    break;
            }

        },
        setActiveFile: (
            state,
            action: PayloadAction<
                any
            >
        ) => {

            const { id: activeFileId } = action.payload.file;
            const checkIfIsActive = state.openFiles && state.openFiles.find((e: { id: string, isActive: boolean }) => e.id === activeFileId)

            if (checkIfIsActive && !checkIfIsActive.isActive) {
                state.openFiles = state.openFiles && state.openFiles.map((e: { id: string }) => { return { ...e, isActive: e.id === activeFileId } });
                state.files = state.files.map(file => file.id === activeFileId ? { ...file, isActive: true } : { ...file, isActive: false });
            }
        },
        onGetFileContentSuccess: (state,
            action: PayloadAction<
                any
            >
        ) => {
            const openFiles = state.openFiles;

            const { file: selectedFile, content } = action.payload.file;

            const updatedFiles = openFiles.map((e: { parent: string, text: string }) => {
                if (joinPaths(e.parent, e.text) === selectedFile) { return { ...e, value: content } } else {
                    return { ...e }
                }
            })
            state.openFiles = updatedFiles
        },
        onTerminalAdd: (state,
            action: PayloadAction<
                any
            >
        ) => {
            // const terminalIndex = state.terminals.findIndex(e => e.id === action.payload.terminal.id)
            // if (terminalIndex === -1) {
            //     state.terminals = [...state.terminals, action.payload.terminal]
            // }
            console.log(action.payload)
            state.terminalTabs = [...state.terminalTabs, action.payload.terminal]
        },
        onTerminalClose: (state,
            action: PayloadAction<
                any
            >
        ) => {
            state.terminalTabs = state.terminalTabs.filter(terminal => terminal.id !== action.payload.terminal?.id)
        },
        onTerminalDataSuccess: (state,
            action: PayloadAction<
                any
            >
        ) => {

            const terminalsData = state.terminals.map(e => { return { ...e, isNew: false } })
            const terminalIndex = terminalsData.findIndex(e => e.id === action.payload.terminal.id)

            if (terminalIndex === -1) {
                state.terminals = [...terminalsData, { ...action.payload.terminal, isNew: true }]
            } else {
                terminalsData[terminalIndex] = { ...action.payload.terminal, isNew: true }
                state.terminals = terminalsData
            }
        },
    },
});

export const editorReducer = editorSlice.reducer;

export const { setEditorOpenFiles, setConfigs, getEditorFiles, onGetFilesSuccess, onAddFileSuccess, onMoveFileOrFolderSuccess, onCopyFileOrFolderSuccess, onDeleteSuccess, setActiveFile, onGetFileContentSuccess, onTerminalDataSuccess, onTerminalAdd, onTerminalClose, setEditorFile } =
    editorSlice.actions;

export const getEditorState = (
    rootState: any
): IEditorState => rootState[GET_EDITOR];

export const selectEditorFiles = createSelector(
    getEditorState,
    (state) => state.files
);

export const selectEditorOpenFiles = createSelector(
    getEditorState,
    (state) => state.openFiles
);


export const selectActiveFile = createSelector(
    getEditorState,
    (state) => state.activeFile
);
export const selectTerminals = createSelector(
    getEditorState,
    (state) => state.terminals
);

export const selectTerminalTabs = createSelector(
    getEditorState,
    (state) => state.terminalTabs
);

export const selectConfigs = createSelector(
    getEditorState,
    (state) => state.configs
);
