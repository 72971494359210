import Typography from '@mui/material/Typography';
import { keycloak } from '@cloudlabs-frontend/utils/auth-utils';
import Configuration from '@cloudlabs-frontend/utils/configuration';
import Axios from 'axios';
// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
// import 'froala-editor/js/plugins/code_view.min.js';
// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
// import 'froala-editor/css/plugins/code_view.min.css';
import 'froala-editor/css/themes/gray.min.css';
import 'froala-editor/css/third_party/embedly.min.css';
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
// import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/third_party/embedly.min.js';

import { get } from 'lodash';
import React, { useEffect } from 'react';
import FroalaEditor from 'froala-editor';
import ReactFroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { Controller, useFormContext } from 'react-hook-form';
import withIRAFormFlavour from '../ira-form-flavour';
import { IRAFormFlavourProps } from '../ira-form-flavour/ira-form-flavour.interfaces';
// import { useFroalaEditorStyles } from './ira-froala-editor-styles';

/* eslint-disable-next-line */
export interface CloudFroalaEditorProps extends IRAFormFlavourProps {
  name: string;
  className?: string;
  model?: string | object | null;
  onModelChange?: object;
  onManualControllerReady?: object;
  tag?: string;
  enablePreview?: boolean;
  customConfig?: object | any;
  viewOnly?: boolean;
  placeholder?: string;
}

export const CloudFroalaEditor = (props: CloudFroalaEditorProps) => {
  const {
    name,
    model,
    onModelChange,
    onManualControllerReady,
    customConfig,
    viewOnly,
    enablePreview,
    placeholder,
  } = withIRAFormFlavour(props);
  const { control, errors } = useFormContext() || {};
  // const classes = useFroalaEditorStyles();

  useEffect(() => {
    // FroalaEditor.DefineIconTemplate('codeAdd', '<i class="fa fa-code"></i>');
    FroalaEditor.DefineIconTemplate('codeAdd', '<i class="fa fa-code"></i>');
    FroalaEditor.DefineIcon('codeAdd', { NAME: 'Code', template: 'codeAdd' });
    FroalaEditor.RegisterCommand('codeAdd', {
      title: 'Code',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: function () {
        this.html.insert(
          `<pre class="fr-class-code">${this.html.getSelected()}</pre>`
        );
        this.undo.saveStep();
      },
    });
  }, []);

  return (
    <React.Fragment>
      {viewOnly ? (
        <FroalaEditorView
          config={{
            ...Configuration.froalaEditorConfig,
            ...customConfig,
          }}
          model={model ?? '<p>Nothing to Preview<p>'}
          onModelChange={onModelChange}
          onManualControllerReady={onManualControllerReady}
        />
      ) : (
        <div>
          <Controller
            as={
              <ReactFroalaEditor
                config={{
                  ...Configuration.froalaEditorConfig,
                  ...customConfig,
                  placeholderText: placeholder ? placeholder : 'Type something',
                  events: {
                    'image.beforeUpload': async function (images: any[]) {
                      const headers = {
                        accept: 'application/json',
                        'Accept-Language': 'en-US,en;q=0.8',
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${keycloak?.token}`,
                      };

                      const body = new FormData();
                      body.append('upload', images[0]);

                      const outThis = this;
                      await Axios.post(
                        `${Configuration.assetUploadEndPoint}`,
                        body,
                        {
                          headers,
                        }
                      )
                        .then(function (res) {
                          (outThis as any).image.insert(
                            res?.data?.location,
                            true,
                            null,
                            (outThis as any).image.get(),
                            null
                          );
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                      return false;
                    },
                  },
                }}
                model={model}
                onModelChange={onModelChange}
                onManualControllerReady={onManualControllerReady}
              />
            }
            name={name}
            valueName="model"
            onChangeName="onModelChange"
            control={control}
          />
          <Typography
            style={{
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '20px',
              color: '#FB5758',
            }}
          >
            {get(errors, name)?.message}
          </Typography>
        </div>
      )}
      {enablePreview && (
        <FroalaEditorView
          config={{
            ...Configuration.froalaEditorConfig,
            ...customConfig,
          }}
          model={model}
        />
      )}
    </React.Fragment>
  );
};

export default CloudFroalaEditor;
