import { SvgIcon } from '@mui/material';
import React from 'react';
import { IconographyProps } from './iconography.interface';

const AssignmentColorIcon = ({
  primaryColor,
  secondaryColor,
  sx,
  ...props
}: IconographyProps) => {
  const primaryDefault = '#6B7580';
  const secondaryDefault = '#999999';
  return (
    <SvgIcon
      sx={{ fill: 'none', width: 51, height: 51, ...sx }}
      {...props}
      viewBox="0 0 51 51"
    >
      <path
        d="M8.08996 42.2199C7.34996 42.2199 6.90996 41.3599 7.31996 40.7099L12.8 31.9799C13.17 31.3999 13.98 31.3999 14.35 31.9799L19.83 40.7099C20.24 41.3599 19.8 42.2199 19.06 42.2199H8.08996Z"
        fill="#A9D8C6"
      />
      <path
        opacity="0.5"
        d="M35.7001 24.5798C40.4663 24.5798 44.3301 20.716 44.3301 15.9498C44.3301 11.1836 40.4663 7.31982 35.7001 7.31982C30.9339 7.31982 27.0701 11.1836 27.0701 15.9498C27.0701 20.716 30.9339 24.5798 35.7001 24.5798Z"
        fill="#FFEBBA"
      />
      <path
        d="M24.8999 41.22C22.6799 41.22 20.4499 41.22 18.2299 41.22C15.6499 41.21 13.6199 39.52 13.1599 36.98C13.0999 36.67 13.0899 36.35 13.0899 36.04C13.0899 29.83 13.0799 23.62 13.0899 17.41C13.0899 14.74 14.8499 12.69 17.4799 12.27C17.7299 12.23 17.9799 12.23 18.2399 12.23C21.1399 12.23 24.0399 12.23 26.9399 12.23C28.6099 12.23 29.9599 12.86 31.0199 14.15C32.5099 15.95 34.0199 17.75 35.5099 19.55C36.2999 20.5 36.6999 21.61 36.6999 22.84C36.7099 27.28 36.7199 31.72 36.6999 36.16C36.6799 39.05 34.4699 41.22 31.5799 41.23C29.3499 41.23 27.1199 41.22 24.8999 41.22Z"
        stroke="#054C72"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.47 31.8198H27.97"
        stroke="#054C72"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.47 23.8198H31.73"
        stroke="#054C72"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.47 27.8198H31.73"
        stroke="#054C72"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default AssignmentColorIcon;
