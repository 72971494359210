import React from 'react';
import Chip from '@mui/material/Chip';
import { IRATagProps } from './Tag.interface';
import { Box } from '@mui/material';

const Tag = ({
  sx,
  bgcolor,
  borderColor = '',
  color,
  outerSx,
  paddingSize = 'small',
  variant = 'rounded-square',
  ...props
}: IRATagProps) => {
  let padding = '0';
  let borderRadius = '0';
  switch (variant) {
    case 'round':
      borderRadius = '30px';
      if (paddingSize === 'small') padding = '4px 8px';
      else padding = '6px 16px';
      break;
    case 'rounded-square':
      borderRadius = '4px';
      if (paddingSize === 'small') padding = '4px 8px';
      else padding = '6px 16px';
      break;
    case 'leaf':
      if (paddingSize === 'small') padding = '4px 8px';
      else padding = '6px 14px';
      borderRadius = '2px 0px 6px 0px';
      break;
    case 'parallel':
    case 'square':
    case 'ribbon':
      if (paddingSize === 'small') padding = '4px 8px';
      else padding = '6px 14px';
      break;
    default:
      padding = '6px 14px';
  }

  return (
    <Box position="relative" sx={outerSx}>
      {variant === 'ribbon' && (
        <Box
          sx={{
            position: 'absolute',
            width: 6,
            height: 3,
            background: (theme) =>
              bgcolor || theme.prism.tag.filled.backgroundColor,
            top: -3,
            left: -6,
            clipPath: 'polygon(100% 0, 0% 100%, 100% 100%)',
          }}
        />
      )}
      <Chip
        {...props}
        sx={{
          color,
          borderRadius,
          borderColor,
          borderStyle: 'solid',
          borderWidth: borderColor ? '1px' : '0',
          position: 'relative',
          height: 'unset',
          background: (theme) =>
            bgcolor || theme.prism.tag.filled.backgroundColor,
          p: padding,
          ...(variant === 'parallel'
            ? {
                clipPath:
                  'polygon(5px 0%, 100% 0%, calc(100% - 5px) 100%, 0% 100%)',
              }
            : {}),
          ...(variant === 'ribbon'
            ? {
                '&:before': {
                  content: '""',
                  clipPath: 'polygon(0 0, 100% 0, 100% 100%, 58% 100%)',
                  background: (theme) =>
                    bgcolor || theme.prism.tag.filled.backgroundColor,
                  // 6 + 0.5 to prevent white space
                  width: '6.5px',
                  height: '100%',
                  position: 'absolute',
                  left: -6,
                  top: 0,
                },
                '&:after': {
                  content: '""',
                  clipPath:
                    'polygon(0% 0%, 100% 0, calc(100% - 8px) 50%, 100% 100%, 0% 100%)',
                  background: (theme) =>
                    bgcolor || theme.prism.tag.filled.backgroundColor,
                  // 3 + 0.5 to prevent white space
                  width: '8.5px',
                  height: '100%',
                  position: 'absolute',
                  right: -8,
                  top: 0,
                },
              }
            : {}),
          '& .MuiChip-label': { p: 0 },
          '& .MuiChip-icon': {
            ml: 0,
            mr: paddingSize === 'small' ? '4px' : '8px',
          },
          ...sx,
        }}
      />
    </Box>
  );
};

export default Tag;
