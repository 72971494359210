import { IConfiguration } from './config.interfaces';

export const Configuration: IConfiguration = {
  keycloakRealm: 'knowledgehut-test',
  keycloakAuthUrl: 'https://stage-accounts.knowledgehut.com/',
  environment: 'test',
  graphqlEndPoint: 'https://testapi.kh3ira.com/cloud-graphql',
  graphqlPreviewEndPoint: 'https://testapi.kh3ira.com/cloud-graphql',
  graphqlEndPointForAE: 'https://qa-assessments-api.knowledgehut.dev/graphql',
  assessmentEngineURL: 'https://qa-assessments.knowledgehut.dev',
  scormUploadEndPoint: 'https://testapi.kh3ira.com/upload/uploadScorm',
  assetUploadEndPoint: 'https://testapi.kh3ira.com/upload',
  videoUploadEndpoint: 'https://testapi.kh3ira.com/upload/vimeo',
  zoomMeetingEndPoint: 'https://testapi.kh3ira.com:7778',
  meetingEndPoint: 'https://meeting.kh3ira.com',
  meetingLeaveUrl: 'https://test.kh3ira.com',
  meetingClientDownloadLink: 'https://zoom.us/download',
  base64UploadEndPoint: 'https://testapi.kh3ira.com/upload/uploadBase64',
  reCaptchaSiteKey: '6LfPQbAUAAAAANOS_oKInImc-I43TRoMv5I5uULM',
  socketEndPoint: 'wss://testapi.kh3ira.com',
  chatEndPoint: 'wss://testchat.kh3ira.com/websocket',
  chatRestAPIEndPoint: 'https://testchat.kh3ira.com',
  codeLabEndPoint: 'https://codelab.kh3ira.com/',
  codeLabUploadProject:
    'https://codelabapi.kh3ira.com/clnxt/v1/Projects/uploadProject',
  codeLabDomain: 'codelab.kh3ira.com',
  cloudLabDomain: 'khcodelab.com',
  socialLogins: {
    google: {
      clientId:
        '895981467470-769v4e7u7jlqo2vovbuci5ep7ihategl.apps.googleusercontent.com',
    },
    gitHub: {
      clientId: 'fc75ef7c33d246a34326',
      redirectUri: 'https://test.kh3ira.com/identity/social-login',
    },
    linkedIn: {
      clientId: '81kh7xmdkqr2vk',
      redirectUri: 'https://test.kh3ira.com/identity/social-login-linkedin',
    },
  },

  froalaKey:
    'te1C2sE6B5B4C5E3H3jC1QUd1Xd1OZJ1ABVJRDRNGGUE1ITrE1D4A3A10B1B6F6B1G4E3==',
  froalaEditorConfig: {
    key: 'te1C2sE6B5B4C5E3H3jC1QUd1Xd1OZJ1ABVJRDRNGGUE1ITrE1D4A3A10B1B6F6B1G4E3==',
    heightMin: 150,
    toolbarSticky: true,
    toolbarBottom: false,
    attribution: false,
    paragraphFormatSelection: true,
    fontFamilyDefaultSelection: 'Open Sans, sans-serif',
    theme: 'gray',
    htmlExecuteScripts: false,
    iframe: false,
    tabSpaces: 0,
    imageDefaultWidth: 0,
    pastePlain: true,
    enter: 'ENTER_BR',
    pluginsEnabled: [
      'table',
      'spell',
      'quote',
      'save',
      'wordPaste',
      'paragraphFormat',
      'paragraphStyle',
      'draggable',
      'align',
      'link',
      'lists',
      'image',
      'url',
      'colors',
      'entities',
      'inlineClass',
      'inlineStyle',
      'spellChecker',
      'fontSize',
      'specialCharacters',
    ],

    fontSize: ['11', '12', '13', '16', '18'],
    fontSizeSelection: true,
    inlineStyles: {
      'Codelab Title':
        'font-size: 13px; color: black; line-height: 23px;  font-weight: 600;font-style: normal;',
      'Codelab SubTitle':
        'font-size: 13px; color: black; line-height: 23px; font-style: italic;font-weight: 400;',
      'Codelab Paragraph':
        'font-size: 12px; color: black; line-height: 20px; font-weight: 400; font-style: normal;',

      'Assessment Question':
        'font-size: 13px; color: black; line-height: 23px;font-weight: 400;',

      'Assessment Options':
        'font-size: 12px; color: rgba(0, 0, 0, 0.8); line-height: 32px; font-weight: 400;',
      'Assessment Reason':
        'font-size: 12px; color: rgba(0, 0, 0, 0.8); line-height: 20px; font-weight: 400;',
      Order:
        'font-size: 12px; color: black; line-height: 24px; font-weight: 400;',
    },
    inlineClasses: {
      'fr-class-code': 'Code',
    },
    linkList: [
      {
        text: 'Froala',
        href: 'https://froala.com',
        target: '_blank',
      },
      {
        text: 'Google',
        href: 'https://google.com',
        target: '_blank',
      },
      {
        text: 'Facebook',
        href: 'https://facebook.com',
        target: '_blank',
      },
    ],
    toolbarButtons: {
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          'codeAdd',
          // 'inlineClass',
          'inlineStyle',
          'fontSize',
          'strikeThrough',
          'subscript',
          'superscript',

          'textColor',
          'backgroundColor',
          'clearFormatting',
        ],
        buttonsVisible: 5,
      },
      moreParagraph: {
        buttons: [
          'formatOL',
          'formatUL',
          'alignLeft',
          'alignCenter',
          'alignRight',
          'alignJustify',
          'paragraphFormat',
          'paragraphStyle',
          'lineHeight',
          'outdent',
          'indent',
          'quote',
          'insertCode',
        ],
        buttonsVisible: 2,
      },

      moreRich: {
        buttons: [
          'insertImage',
          'insertLink',
          'insertTable',
          'insertHR',
          'specialCharacters',
        ],
        buttonsVisible: 2,
      },
    },
    imageUploadURL: 'https://testapi.kh3ira.com/upload',
    imageUploadParam: 'upload',
    imageAddNewLine: true,
    imagePasteProcess: true,
  },
  vismayaApiEndPoint: 'https://vismaya.io/api',
  sampleCsvFormatForBulkInvite:
    'https://ira-dev.s3.ap-south-1.amazonaws.com/b887a9c7-7284-4216-bf7b-7bf456b6d816-invite-users-assessment-csv-format.csv',
  fileReaderEndpoint: 'https://testapi.kh3ira.com/resources',
  websiteEndpoint: 'https://test.knowledgehut.com/prism_auth/login',
  purchaseHistoryEndPoint:
    'https://test.knowledgehut.com/profile/my-purchase-history',
  referAndEarnEndPoint: 'https://test.knowledgehut.com/profile/refer-earn',
  certificatesEndPoint: 'https://test.knowledgehut.com/profile/certificates',
  knowledgeBaseEndPoint: 'https://support.knowledgehut.com/support/home',
  pdfViewerEndpoint: 'https://pdfviewer.kh3ira.com',
  openReplayConfig: 'gOOiPyR6M33A9hfuoYjL',
  openReplayIngestEndPoint: 'https://or.knowledgehut.com/ingest',
  s3Endpoint: 'https://dlsysctoqshlm.cloudfront.net/',
  linkedInCertificateEndPoint: 'https://testapi.kh3ira.com/linkedin',
  loginUrl: 'https://test.knowledgehut.com'
};

export default Configuration;
