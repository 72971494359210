import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICloudBasedUserLabAssetState } from './interface';

export const GET_CLOUD_BASED_USER_LAB_ASSET = 'cloudUserLabAsset';

const initialGetCloudBasedUserLabAssetState: ICloudBasedUserLabAssetState = {
  loaded: false,
  cloudUserLabAsset: {},
  isLoading: false,
  isLoaded: false,
  isValidate: false,
  error: null,
};

export const cloudUserLabAssetSlice = createSlice({
  name: GET_CLOUD_BASED_USER_LAB_ASSET,
  initialState: initialGetCloudBasedUserLabAssetState as ICloudBasedUserLabAssetState,
  reducers: {
    getCloudUserLabAsset: (state, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.isLoaded = true;
    },
    getPreviewUserLabAsset: (state, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.isLoaded = true;
    },
    getCloudUserLabAssetSuccess: (
      state,
      action: PayloadAction<
        any
      >
    ) => {
      state.cloudUserLabAsset = action.payload.cloudUserLabAsset || [];
      state.isLoading = false;
      state.isLoaded = false;
    },
    getCloudUserLabAssetFailure: (state, action: PayloadAction<Error>) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isLoaded = false;
    },
    startCloudUserLabAsset: (state, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.isLoaded = true;
    },
    stopCloudUserLabAsset: (state, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.isLoaded = true;
    },
    validateCloudUserLabAsset: (state, action: PayloadAction<any>) => {
      state.isValidate = true;
    },
    getValidateAssetResponse: (
      state,
      action: PayloadAction<
        any
      >
    ) => {
      state.cloudUserLabAsset.testCases = action.payload?.testCases || []
      state.isValidate = false;
    },
  },
});

export const cloudUserLabAssetReducer = cloudUserLabAssetSlice.reducer;

export const { getCloudUserLabAsset, getCloudUserLabAssetSuccess, getCloudUserLabAssetFailure, startCloudUserLabAsset, stopCloudUserLabAsset, validateCloudUserLabAsset, getValidateAssetResponse, getPreviewUserLabAsset } =
  cloudUserLabAssetSlice.actions;

export const getCloudUserLabAssetState = (
  rootState: any
): ICloudBasedUserLabAssetState => rootState[GET_CLOUD_BASED_USER_LAB_ASSET];

export const selectCloudUserLabAsset = createSelector(
  getCloudUserLabAssetState,
  (state) =>
    state.cloudUserLabAsset
);

export const selectIsLoading = createSelector(
  getCloudUserLabAssetState,
  (state) => state.isLoading
);


export const selectIsLoaded = createSelector(
  getCloudUserLabAssetState,
  (state) => state.isLoaded
);

export const selectIsValidate = createSelector(
  getCloudUserLabAssetState,
  (state) => state.isValidate
);


export const selectTestCasesResponse = createSelector(
  getCloudUserLabAssetState,
  (state) => state.cloudUserLabAsset.testCases
);

