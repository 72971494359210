import Button from '@cloudlabs-frontend/components/core/Button';
import CloudTypography from '@cloudlabs-frontend/components/core/CloudTypography';
import { sendMessage } from '@cloudlabs-frontend/features/socket';
import { IStyles } from '@cloudlabs-frontend/lib/utils/interfaces';
import {
  DndProvider,
  getBackendOptions,
  MultiBackend,
  Tree,
} from '@minoru/react-dnd-treeview';
import { DropOptions, NodeModel } from '@minoru/react-dnd-treeview/dist/types';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  onGetFilesSuccess,
  onTerminalAdd,
  selectEditorFiles,
} from '../../editor.slice';
import { CustomDragPreview } from './components/CustomDragPreview';
import { CustomNode } from './components/CustomNode';
import styles from './components/tree.module.css';
import { CloseIcon } from '@cloudlabs-frontend/components/core/Iconography';
import { localStore } from '@cloudlabs-frontend/lib/utils/localStore';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
/* eslint-disable-next-line */
export interface FileTreeProps extends IStyles {}

interface IContextPoints {
  x: string | number;
  t: string | number;
  b: string | number;
}

const rightClickMenus = [
  {
    id: 'NEW_FILE',
    label: 'New File...',
    showIn: ['DIRECTORY'],
  },
  {
    id: 'NEW_FOLDER',
    label: 'New Folder...',
    showIn: ['DIRECTORY'],
  },
  {
    id: 'TERMINAL',
    label: 'Open in Integrated Terminal',
    showIn: ['DIRECTORY'],
  },
  {
    id: 'COPY',
    label: 'Copy',
    showIn: ['DIRECTORY', 'FILE'],
  },
  {
    id: 'PASTE',
    label: 'Paste',
    showIn: ['DIRECTORY'],
  },
  {
    id: 'RENAME',
    label: 'Rename...',
    showIn: ['DIRECTORY', 'FILE'],
  },
  {
    id: 'DELETE',
    label: 'Delete...',
    showIn: ['DIRECTORY', 'FILE'],
  },
];

export function FileTree(props: any) {
  const { rootPath } = props;
  const dispatch = useDispatch();
  const treeData = useSelector(selectEditorFiles);

  const [isInitial, setIsInitial] = useState(true);
  const [rightClick, setRightClick] = useState(false);
  const [rightClickData, setRightClickData] = useState({}) as any;
  const [modelData, setModelData] = useState(null) as any;
  const [copyData, setCopyData] = useState(null) as any;
  const [fileName, setFileName] = useState(null);
  const [contextMenuPoints, setContextMenuPoints] = useState<IContextPoints>({
    x: 0,
    t: 0,
    b: 0,
  });

  const prevData = localStore({
    name: 'FOLDER',
    type: 'GET',
    isObject: true,
  }) || { ids: [] };

  const handleDrop = (newTree: NodeModel[], options: DropOptions) => {
    const { dropTargetId, monitor, dragSource, dropTarget } = options;
    const {
      id: dragId,
      text: dragText,
      parent: dragParent,
      type: dragType,
    } = dragSource as any;
    const {
      id: dropId,
      text: dropText,
      parent: dropParent,
      type: dropType,
    } = dropTarget as any;
    const itemType = monitor.getItemType();
    dispatch(
      sendMessage({
        handler: 'MOVE_FILE_OR_FOLDER',
        message: {
          from: {
            id: dragId,
            text: dragText,
            parent: dragParent,
            type: dragType,
          },
          to: {
            id: dropId,
            text: dropText,
            parent: dropParent,
            type: dropType,
          },
        },
      })
    );
  };

  const handleRightClick = (e) => {
    const { clickProps = {} } = e;

    setRightClickData(clickProps);

    setRightClick(false);

    setRightClick(true);
    setContextMenuPoints({
      x: e.pageX,
      t: e.pageY + 200 < e.view.window.innerHeight ? e.pageY : 'auto',
      b:
        e.pageY + 200 > e.view.window.innerHeight
          ? e.view.window.innerHeight - e.pageY
          : 'auto',
    });
  };

  const handleInitialOpen = (nodeId) => {
    // const checkIfAlreadyExists = initialOpen.findIndex((e) => e === nodeId);
    // const updatedInitialOpenFiles = initialOpen;
    // if (checkIfAlreadyExists !== -1) {
    //   updatedInitialOpenFiles.splice(checkIfAlreadyExists, 1);
    //   setInitialOpen([...initialOpen]);
    // } else {
    //   setInitialOpen([...updatedInitialOpenFiles, nodeId]);
    // }
  };

  const handleContentClick = (id, label, data) => {
    switch (id) {
      case 'NEW_FILE':
        setModelData({ ...data, modalType: 'ADD_FILE', label });
        break;
      case 'NEW_FOLDER':
        setModelData({ ...data, modalType: 'ADD_DIRECTORY', label });
        break;
      case 'TERMINAL':
        const terminalId = randomInt();
        const { type, parent, id: path } = data;
        dispatch(
          onTerminalAdd({
            terminal: { id: terminalId, value: '', active: false },
          })
        );

        setTimeout(() => {
          dispatch(
            sendMessage({
              handler: 'initiate',
              message: {
                id: terminalId,
                user: 'vasista',
                path: type === 'DIRECTORY' ? path : parent,
                isCreateLog: false,
              },
            })
          );
        }, 1000);

        break;
      case 'RENAME':
        setModelData({ ...data, modalType: 'RENAME', label });
        setFileName(data?.text);

        break;
      case 'DELETE':
        setModelData({ ...data, modalType: 'DELETE', label });
        break;
      case 'COPY':
        setCopyData({ ...data });
        break;
      case 'PASTE':
        dispatch(
          sendMessage({
            handler: 'COPY_FILE_OR_FOLDER',
            message: {
              from: { ...copyData },
              to: { ...data },
            },
          })
        );
        break;
      default:
        break;
    }
  };

  const handleFile = () => {
    const { parent: parentPath, type, id: path, text } = modelData;

    switch (modelData?.modalType) {
      case 'ADD_DIRECTORY':
        dispatch(
          sendMessage({
            handler: 'ADD_FILE_OR_FOLDER',
            message: {
              file: `${
                type === 'DIRECTORY' ? path : parentPath
              }/${fileName}/.empty`,
              type: 'DIRECTORY',
              text: fileName,
              parent: `${type === 'DIRECTORY' ? path : parentPath}`,
            },
          })
        );
        break;
      case 'ADD_FILE':
        dispatch(
          sendMessage({
            handler: 'ADD_FILE_OR_FOLDER',
            message: {
              file: `${type === 'DIRECTORY' ? path : parentPath}/${fileName}`,
              type: 'FILE',
              text: fileName,
              parent: `${type === 'DIRECTORY' ? path : parentPath}`,
            },
          })
        );
        break;
      case 'DELETE':
        dispatch(
          sendMessage({
            handler: 'DELETE_FILE_OR_FOLDER',
            message: {
              file: `${path}`,
              type: type,
              text: fileName,
              parent: `${parentPath}`,
            },
          })
        );
        break;
      case 'RENAME':
        dispatch(
          sendMessage({
            handler: 'RENAME_FILE_OR_FOLDER',
            message: {
              from: {
                id: `${path}`,
                type: type,
                text: text,
                parent: `${parentPath}`,
              },
              to: {
                id: `${path.replace(text, fileName)}`,
                type: type,
                text: fileName,
                parent: `${parentPath}`,
              },
            },
          })
        );
        break;
      default:
        break;
    }
  };

  const randomInt = () => {
    const min = 10000000; // Smallest 8-digit number
    const max = 99999999; // Largest 8-digit number

    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber;
  };

  useEffect(() => {
    const handleClick = () => setRightClick(false);
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const sortByName = (a, b) => {
    const nameA = a.id.toLowerCase();
    const nameB = b.id.toLowerCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  };

  useEffect(() => {
    setModelData(null);
    setFileName(null);

    if (isInitial && treeData.length > 0) {
      dispatch(
        sendMessage({
          handler: 'GET_CONFIGS',
          message: {
            path: rootPath,
          },
        })
      );
      setIsInitial(false);
    }
  }, [treeData]);

  return (
    <Stack
      width={'100%'}
      height={'100%'}
      sx={{ bgcolor: '#262525' }}
      className={`${styles['explorer']}`}
    >
      <Box>
        <CloudTypography
          textVariant="h11"
          bgcolor={'#262525'}
          sx={{
            padding: '6px 15px',
            color: '#ffffff',
            textTransform: 'uppercase',
          }}
        >
          Explorer
        </CloudTypography>
      </Box>
      <Box
        sx={{ boxShadow: '0 1px 0 rgb(255 255 255 / 0.3) inset' }}
        className={`${styles['file-list']}`}
      >
        <DndProvider backend={MultiBackend} options={getBackendOptions()}>
          <Tree
            rootId={0}
            tree={treeData}
            extraAcceptTypes={['FILE']}
            initialOpen={prevData.ids}
            // sort={sortByName}
            classes={{
              root: styles['treeRoot'],
              draggingSource: styles['raggingSource'],
              dropTarget: styles['dropTarget'],
            }}
            render={(node, options) => (
              <CustomNode
                node={node}
                {...options}
                setInitialOpen={handleInitialOpen}
                handleRightClick={handleRightClick}
              />
            )}
            dragPreviewRender={(monitorProps: any) => (
              <CustomDragPreview monitorProps={monitorProps} />
            )}
            onDrop={(newTree: NodeModel[], options: DropOptions) =>
              handleDrop(newTree, options)
            }
          />
        </DndProvider>
      </Box>
      {rightClick && (
        <Box
          top={contextMenuPoints.t}
          left={contextMenuPoints.x}
          bottom={contextMenuPoints.b}
          position={'fixed'}
          width={300}
          sx={{ backgroundColor: '#fff', zIndex: 99 }}
        >
          {rightClickMenus.map(({ id, label, showIn }) => (
            <Box>
              {showIn.includes(rightClickData.type) && (
                <ListItem key={id} component="div" disablePadding>
                  <ListItemButton
                    sx={{ py: 0, minHeight: 28 }}
                    disabled={id === 'PASTE' && !copyData}
                  >
                    <ListItemText
                      primary={label}
                      primaryTypographyProps={{
                        fontSize: 14,
                        fontWeight: 'medium',
                      }}
                      onClick={() =>
                        handleContentClick(id, label, rightClickData)
                      }
                    />
                  </ListItemButton>
                </ListItem>
              )}
            </Box>
          ))}
        </Box>
      )}

      <Dialog open={Boolean(modelData)} fullWidth={true}>
        <DialogTitle
          sx={{ backgroundColor: '#007acc', p: 1.5 }}
          fontSize={14}
          position={'relative'}
        >
          {modelData?.label || '...'}
          <IconButton
            color="inherit"
            onClick={() => setModelData(null)}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 5,
              top: 6,
              p: 0,
            }}
          >
            <CloseIcon sx={{ height: 22 }} primaryColor={'#fff'} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mt: 5 }}>
          {modelData?.modalType !== 'DELETE' ? (
            <Input
              id={'name'}
              name={'name'}
              fullWidth={true}
              defaultValue={fileName}
              onChange={(e) => setFileName(e.target.value)}
              sx={{ height: 16 }}
            />
          ) : (
            <CloudTypography textVariant="h11">
              Do you really want to delete {modelData?.text}?
            </CloudTypography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            btnVariant="primary"
            btnSize="small"
            onClick={handleFile}
            sx={{ height: 30, borderRadius: 0, backgroundColor: '#007acc' }}
          >
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

export default FileTree;
