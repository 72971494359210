import { useKeycloak } from '@react-keycloak/web';
import { Suspense } from 'react';
import React from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';

import ScrollToTop from './scroll-top';

// import DashboardStatus from '@prism/features/Dashboard/dashboard-status';
// import { ContentConsumption, ProgramDetail, Onboarding } from '@prism/features';
const LazyCloudBased = React.lazy(
  () => import('@cloudlabs-frontend/features/Landing')
);

const LazyKubernetesBased = React.lazy(
  () => import('@cloudlabs-frontend/features/kubernetes/Landing')
);

export default () => {
  const [searchParams] = useSearchParams();

  const hostName = window.location.hostname.split('.')[0];

  const filePath = window.location.hash.replace('#', '');

  const subDomain =
    hostName === 'preview' ? searchParams.get('questionid') : hostName;

  const { initialized, keycloak } = useKeycloak();

  if (!initialized || !keycloak.authenticated) {
    return <>Loading</>;
  }

  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<Navigate to="/identity/login" replace />} />
        <Route
          path={`/cloud-based`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <LazyCloudBased
                subDomain={subDomain}
                preview={hostName === 'preview'}
              />
            </Suspense>
          }
        />
        <Route
          path={`/kubernetes`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <LazyKubernetesBased
                subDomain={subDomain}
                preview={hostName === 'preview'}
                filePath={filePath}
              />
            </Suspense>
          }
        />
      </Routes>
    </ScrollToTop>
  );
};
