import gql from 'graphql-tag';

export const QUERY_GET_CLOUD_USER_LAB_ASSET = gql`
  query cloudUserLabAsset(
    $where: CloudUserLabAssetWhereUniqueInput!
  ) {
    cloudUserLabAsset(where: $where){
      status
      slug
      userName
      password
      url
      organizationAccount{
        id
      }
      accessKey
    secretKey
    creditsInSeconds
    startedAt
    attempts
    attemptsLeft
    testCases{
      name
      suiteName
      step
      status
    }
      cloudLabAsset{
        name
        type
        testCases{
          suiteName
          functionName
          step
          failureMessages
        }
        instructions{
          instruction
          step
        }
      }
      otherRunningAsset {
        id
        slug
        cloudLabAsset {
          name
        }
        additionalInfo
      }
    }
    
  }
`;


export const MUTATION_START_CLOUD_USER_LAB_ASSET = gql`
  mutation startCloudUserLabAsset(
    $where: CloudUserLabAssetWhereUniqueInput!
  ) {
    startCloudUserLabAsset(where: $where){
      status
      slug
      userName
      password
      url
      organizationAccount{
        id
      }
      accessKey
    secretKey
    creditsInSeconds
    startedAt
    attempts
    attemptsLeft
    testCases{
      name
      suiteName
      step
      status
    }
      cloudLabAsset{
        name
        type
        testCases{
          suiteName
          functionName
          step
          failureMessages
        }
        instructions{
          instruction
          step
        }
      }
    }
    
  }
`;


export const MUTATION_STOP_CLOUD_USER_LAB_ASSET = gql`
  mutation stopCloudUserLabAsset(
    $where: CloudUserLabAssetWhereUniqueInput!
  ) {
    stopCloudUserLabAsset(where: $where){
      status
      slug
      userName
      password
      url
      organizationAccount{
        id
      }
      accessKey
    secretKey
    attempts
    attemptsLeft
    testCases{
      name
      suiteName
      step
      status
    }
      cloudLabAsset{
        name
        type
        testCases{
          suiteName
          functionName
          step
          failureMessages
        }
        instructions{
          instruction
          step
        }
      }
    }
    
  }
`;


export const MUTATION_VALIDATE_CLOUD_USER_LAB_ASSET = gql`
  mutation validateCloudUserLabAsset(
    $where: ValidateCloudUserLabAssetInput!
  ) {
    validateCloudUserLabAsset(where: $where){
      testCases {
        name
        suiteName
        step
        status
        failureMessages
      } 
    }
  }
`;


// export const QUERY_GET_PREVIEW_LAB_ASSET = gql`
//   query previewCloudUserLabAsset(
//     $where: PreviewCloudUserLabAssetUniqueInput!
//   ) {
//     previewCloudUserLabAsset(where: $where){
//       status
//       slug
//       userName
//       password
//       url
//       accessKey
//     secretKey
//     creditsInSeconds
//     startedAt
//     attempts
//     attemptsLeft
//       cloudLabAsset{
//         name
//         type
//         testCases{
//           suiteName
//           functionName
//           step
//           failureMessages
//         }
//         instructions{
//           instruction
//           step
//         }
//       }

//     }

//   }
// `;


export const QUERY_GET_PREVIEW_LAB_ASSET = gql`
  query cloudQuestion(
    $id: ID!
  ) {
    cloudQuestion(id: $id){
      id
      status
      type
      title
      resubmissionTimes
      instructions
      testCases
     
    }
    
  }
`;

