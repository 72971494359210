///<reference types="webpack-env" />
import { configureStore } from '@reduxjs/toolkit';

import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';


const sagaMiddleware = createSagaMiddleware({
  onError: (error, { sagaStack }) => {
    console.error('Caught in Saga Root', { error, sagaStack });
  },
});

// import { loaderStart, loaderStop } from '../loader';

const DEBUG = false;

const loaderMiddleware = (store: any) => (next: any) => (action: any) => {
  if (
    action.type !== 'loader/loaderStart' &&
    action.type.toLowerCase().endsWith('start')
  ) {
    DEBUG && console.debug('loaderMiddleware:loaderStart', action);
    // store.dispatch(loaderStart());
  }
  if (action.type.toLowerCase().endsWith('success')) {
    DEBUG && console.debug('loaderMiddleware:loaderStop', action);
    // store.dispatch(loaderStop());
    if (action.payload?.notification?.message) {
      // store.dispatch(
      //   enqueueSnackbar({
      //     message: action.payload?.notification?.message,
      //     options: {
      //       variant: 'success',
      //     },
      //   })
      // );
    }
  }

  if (action.type.toLowerCase().endsWith('failure')) {
    DEBUG && console.debug('loaderMiddleware:loaderStop', action);
    // store.dispatch(loaderStop());
    // store.dispatch(
    //   enqueueSnackbar({
    //     message: action.payload,
    //     options: { variant: 'error' },
    //   })
    // );
  }

  next(action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ serializableCheck: false }),
    sagaMiddleware,
    loaderMiddleware,
  ],
});

sagaMiddleware.run(rootSaga);

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;

export default store;
