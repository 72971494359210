import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

import styles from './CustomNode.module.css';
import { TypeIcon } from './TypeIcon';
import { Box } from '@mui/material';
import {
  selectActiveFile,
  selectEditorOpenFiles,
  setActiveFile,
  setEditorOpenFiles,
} from '../../../editor.slice';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { localStore } from '@cloudlabs-frontend/lib/utils/localStore';
import { sendMessage } from '@cloudlabs-frontend/features/socket';

const useStyles = makeStyles()(() => {
  return {
    active: {
      backgroundColor: '#403f3f',
    },
  };
});

type Props = {
  node: NodeModel;
  depth: number;
  isOpen: boolean;
  onToggle: (id: NodeModel['id']) => void;
  handleRightClick: any;
  setInitialOpen: (id: NodeModel['id']) => void;
};

type NodeModel<T = unknown> = {
  id: number | string;
  parent: number | string;
  text: string;
  droppable?: boolean;
  data?: T;
  type?: string;
  isActive?: boolean;
};

export const CustomNode: React.FC<Props> = (props) => {
  const { node, onToggle, isOpen, handleRightClick, setInitialOpen } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const filesOpened = useSelector(selectEditorOpenFiles);
  // const activeFile = useSelector(selectActiveFile);

  const { droppable, type, id: fileId, text: fileName } = node;

  const indent = props.depth * 1;

  const handleToggle = (e: React.MouseEvent, node) => {
    e.stopPropagation();
    setInitialOpen(fileId);
    onToggle(fileId);
    const { loadMore = false, id: loadMorePath } = node;
    if (loadMore) {
      // dispatch(
      //   sendMessage({
      //     handler: 'LOAD_MORE_FILES',
      //     message: { path: loadMorePath },
      //   })
      // );
    }

    const prevData = localStore({
      name: 'FOLDER',
      type: 'GET',
      isObject: true,
    }) || { ids: [] };

    const fileIndex = prevData.ids.indexOf(fileId);
    fileIndex === -1
      ? prevData.ids.push(fileId)
      : prevData.ids.splice(fileIndex, 1);

    localStore({
      name: 'FOLDER',
      type: 'SAVE',
      isObject: true,
      data: { ids: prevData.ids },
    });
  };

  const handleSelect = (node) => {
    const isAlreadyOpened =
      filesOpened &&
      filesOpened.findIndex(
        (e: NodeModel) =>
          e.id === node.id && e.parent === node.parent && e.text === node.text
      );
    if (isAlreadyOpened === -1) {
      dispatch(
        setEditorOpenFiles({
          files: [...filesOpened, { ...node, isDirty: false }],
        })
      );
    }
    dispatch(setActiveFile({ file: node }));
  };

  return (
    <div
      className={`tree-node ${styles?.['root']} ${styles?.['no-icon']} ${
        styles?.['cursorPointer']
      } ${node.isActive ? classes.active : 'not-active'}`}
      style={{ paddingInlineStart: indent }}
      onContextMenu={(e) => {
        e.preventDefault();
        handleRightClick({ ...e, clickProps: node });
      }}
      onClick={(e) => {
        type === 'DIRECTORY' ? handleToggle(e, node) : handleSelect(node);
      }}
    >
      {droppable === true &&
        (type === 'DIRECTORY' || type === 'SYMBOLIC_LINK') && (
          <div
            className={`${styles?.['expandIconWrapper']} ${
              isOpen ? styles?.['isOpen'] : ''
            }`}
          >
            <div>
              <KeyboardArrowRightIcon />
            </div>
          </div>
        )}
      {type === 'FILE' && <TypeIcon file={fileName} />}
      <div className={[styles?.['labelGridItem']].join(' ')}>
        <Typography className={`${styles?.['text13']} `} variant="body2">
          {fileName}{' '}
        </Typography>
      </div>
      {props.node.type === 'SYMBOLIC_LINK' && (
        <div
          className={`${styles?.['expandIconWrapper']} ${styles?.['justifyEnd']}`}
        >
          <SubdirectoryArrowRightIcon />
        </div>
      )}
    </div>
  );
};
