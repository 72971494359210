import { ChipProps, SxProps } from '@mui/material';

export interface IRATagProps
  extends Omit<ChipProps, 'size' | 'type' | 'color' | 'variant'> {
  borderColor?: string;
  color?: string;
  paddingSize?: 'small' | 'large';
  bgcolor?: string;
  variant?:
    | 'square'
    | 'round'
    | 'rounded-square'
    | 'leaf'
    | 'parallel'
    | 'ribbon';
  outerSx?: SxProps;
}

export enum TagVariant {
  square = 'square',
  round = 'round',
  roundedSquare = 'rounded-square',
  leaf = 'leaf',
  parallel = 'parallel',
  ribbon = 'ribbon',
}

export enum TagSize {
  small = 'small',
  large = 'large',
}

export type IRARibbonTagProps = Omit<IRATagProps, 'variant'>;

export interface IRAColourTagProps
  extends Omit<ChipProps, 'size' | 'type' | 'color' | 'variant'> {
  color?: string;
  bgcolor?: string;
}

export interface IRAIndicationTagProps
  extends Omit<ChipProps, 'size' | 'type' | 'color' | 'variant'> {
  color?: string;
  bgcolor?: string;
}

export interface IRADoubleTagProps
  extends Omit<ChipProps, 'size' | 'type' | 'color' | 'variant' | 'label'> {
  topLabel?: string | JSX.Element | JSX.Element[];
  topColor?: string;
  bottomLabel?: string | JSX.Element | JSX.Element[];
  bottomColor?: string;
  paddingSize?: 'small' | 'large';
  topBgcolor?: string;
  bottomBgcolor?: string;
}
