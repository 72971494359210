/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import CloudTypography from '@cloudlabs-frontend/components/core/CloudTypography';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Grid from '@mui/material/Grid/Grid';
import React from 'react';
import { useEffect, useState } from 'react';

import { ICloudBasedUserLabAsset } from '../../landing/interface';

interface ITimer {
  seconds: number;
  secondsLeft: number;
  timerCleared: any;
  cloudUserLabAsset: ICloudBasedUserLabAsset;
}

const Timer = (props: ITimer) => {
  const { seconds: propSeconds, secondsLeft, timerCleared } = props;

  const [seconds, setSeconds] = useState(secondsLeft);

  useEffect(() => {
    if (!secondsLeft) return;
    setSeconds(secondsLeft);
  }, [secondsLeft]);

  useEffect(() => {
    if (seconds) {
      const progress_timer = setInterval(() => {
        setSeconds((second) => second - 1);
      }, 1000);

      if (seconds <= 1) {
        clearInterval(progress_timer);
        timerCleared();
      }

      return () => {
        clearInterval(progress_timer);
      };
    }
  }, [seconds]);

  const returnElapsedTime = (secondsLeft: number) => {
    const hours = Math.floor(secondsLeft / 3600);
    const minutes = Math.floor((secondsLeft % 3600) / 60);
    const remainingSeconds = secondsLeft % 60;
    return `${hours > 0 ? `${hours.toString().padStart(2, '0')}:` : ''}${minutes
      .toString()
      .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item sx={{ height: '24px' }}>
        <AccessTimeIcon sx={{ color: '#6B7580' }}></AccessTimeIcon>
      </Grid>
      <Grid item>
        <CloudTypography
          textVariant="h8WithBold"
          sx={{ display: 'inline-block', px: '10px', color: '#33AB3F' }}
        >
          {returnElapsedTime(seconds)}
        </CloudTypography>
        <CloudTypography textVariant="h8" sx={{ display: 'inline-block' }}>
          Remaining
        </CloudTypography>
      </Grid>
    </Grid>
  );
};

export default Timer;
