import React from 'react';

import styles from './CustomDragPreview.module.css';

type Props = {
  monitorProps: any;
};

export const CustomDragPreview: React.FC<Props> = (props) => {
  const item = props.monitorProps.item;

  return (
    <div className={styles['root']}>
      <div className={styles['icon']}>s</div>
      <div className={styles['label']}>{item.text}</div>
    </div>
  );
};
