import styled from 'styled-components';

/* eslint-disable-next-line */
export interface UtilsProps {}

const StyledUtils = styled.div`
  color: pink;
`;
export const COMMON_DICTIONARY = {
  SAVE: 'SAVE',
  GET: 'GET',
  REMOVE: 'REMOVE',
  USER_ACCESS_TOKEN: 'user_access_token',
  REALM: 'demo',
  SUBMIT: 'Submit',
  GET_STARTED_NOW: 'GET STARTED NOW',
};

export function Utils(props: UtilsProps) {
  return (
    <StyledUtils>
      <h1>Welcome to Utils!</h1>
    </StyledUtils>
  );
}

export default Utils;
