export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({
  xs,
  sm,
  md,
  lg,
  xl,
}: {
  xs: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}) {
  return {
    fontSize: pxToRem(xs),
    ...(sm
      ? {
        '@media (min-width:600px)': {
          fontSize: pxToRem(sm),
        },
      }
      : {}),
    ...(md
      ? {
        '@media (min-width:1024px)': {
          fontSize: pxToRem(md),
        },
      }
      : {}),
    ...(lg
      ? {
        '@media (min-width:1200px)': {
          fontSize: pxToRem(lg),
        },
      }
      : {}),
    ...(xl
      ? {
        '@media (min-width:1440px)': {
          fontSize: pxToRem(xl),
        },
      }
      : {}),
  };
}
