import Box from '@mui/material/Box/Box';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { makeStyles } from 'tss-react/mui';

import { CloseIcon } from '../iconography';

// import blueStopImg from 'libs/common/src/lib/components/Assets/png/activity-alert-images/stop-blue-img.png';
// import codeNoteSearchImg from 'libs/common/src/lib/components/Assets/png/activity-alert-images/code-note-search-img.png';
// import waitTimeImg from 'libs/common/src/lib/components/Assets/png/activity-alert-images/wait-time-img.png';
// import calendarBadgeImg from 'libs/common/src/lib/components/Assets/png/activity-alert-images/calendar-badge-img.png';
// import calendarUnlockImg from 'libs/common/src/lib/components/Assets/png/activity-alert-images/calendar-unlock-img.png';
// import cloudCodeImg from 'libs/common/src/lib/components/Assets/png/activity-alert-images/cloud-code-img.png';
// import codeIconImg from 'libs/common/src/lib/components/Assets/png/activity-alert-images/code-icon-img.png';
// import blueListImg from 'libs/common/src/lib/components/Assets/png/activity-alert-images/blue-list-img.png';
// import codePenImg from 'libs/common/src/lib/components/Assets/png/activity-alert-images/code-pen-img.png';
// import learnerCommentImg from 'libs/common/src/lib/components/Assets/png/activity-alert-images/learner-comment-img.png';
// import learnerImg from 'libs/common/src/lib/components/Assets/png/activity-alert-images/learner-img.png';
// import listAndUserImg from 'libs/common/src/lib/components/Assets/png/activity-alert-images/list-and-user-img.png';
// import pauseImg from 'libs/common/src/lib/components/Assets/png/activity-alert-images/pause-img.png';
// import stopBlueImg from 'libs/common/src/lib/components/Assets/png/activity-alert-images/stop-blue-img.png';
// import timeCloseImg from 'libs/common/src/lib/components/Assets/png/activity-alert-images/time-close-img.png';
// import calenderMarkImg from 'libs/common/src/lib/components/Assets/png/activity-alert-images/calender-mark-img.png';
// import bookLockedImg from 'libs/common/src/lib/components/Assets/png/activity-alert-images/book-locked-img.png';
// import todoListImg from 'libs/common/src/lib/components/Assets/png/activity-alert-images/todo-list-img.png';
// import closeIcon from 'libs/common/src/lib/components/Assets/png/closeIconBlack.png';
export interface CloudPopupProps extends DialogProps {
  open: boolean;
  onClose?: () => void;
  // iconType?: string;
  width?: string;
  bannerImage?: string;
  headerContent?: React.ReactNode;
  descriptionContent?: React.ReactNode;
  actionButtons?: React.ReactNode;
  bannerBottomPosition?: string;
  isCloseIconRequired?: boolean;
  bannerColor?: string;
}

const useStyles = makeStyles()(() => {
  return {
    highOrderPopup: { zIndex: 1400 },
    alertBanner: {
      position: 'relative',
      //backgroundColor: alertBannerColor || '#FFF0BC',
      // height: '119px',
      display: 'flex',
      justifyContent: 'center',
    },
    bannerObject: {
      position: 'relative',
      bottom: '-20px',
    },
    alertCloseIcon: {
      position: 'absolute',
      right: '16px',
      top: '12px',
      cursor: 'pointer',
      borderRadius: '50%',
    },
    alertBody: {
      padding: '40px',
      textAlign: 'center',
    },
    alertActions: {
      display: 'flex',
      justifyContent: 'center',
      gap: '0 16px',
      marginTop: '24px',
    },
  };
});
export const CloudPopup = (props: CloudPopupProps) => {
  const {
    open,
    onClose,
    children,
    // iconType,
    bannerImage,
    width,
    actionButtons,
    bannerBottomPosition,
    bannerColor,
    isCloseIconRequired,
    headerContent,
    descriptionContent,
  } = props;

  const { classes } = useStyles();
  const handleClose = () => {
    onClose();
  };

  // const alertIcons = [
  //   { name: 'blueStopImg', icon: blueStopImg },
  //   { name: 'codeNoteSearchImg', icon: codeNoteSearchImg },
  //   { name: 'waitTimeImg', icon: waitTimeImg },
  //   { name: 'calendarBadgeImg', icon: calendarBadgeImg },
  //   { name: 'calendarUnlockImg', icon: calendarUnlockImg },
  //   { name: 'cloudCodeImg', icon: cloudCodeImg },
  //   { name: 'codeIconImg', icon: codeIconImg },
  //   { name: 'blueListImg', icon: blueListImg },
  //   { name: 'codePenImg', icon: codePenImg },
  //   { name: 'learnerCommentImg', icon: learnerCommentImg },
  //   { name: 'learnerImg', icon: learnerImg },
  //   { name: 'listAndUserImg', icon: listAndUserImg },
  //   { name: 'pauseImg', icon: pauseImg },
  //   { name: 'stopBlueImg', icon: stopBlueImg },
  //   { name: 'timeCloseImg', icon: timeCloseImg },
  //   { name: 'calenderMarkImg', icon: calenderMarkImg },
  //   { name: 'bookLockedImg', icon: bookLockedImg },
  //   { name: 'todoListImg', icon: todoListImg },
  // ];

  // const iconToRender = alertIcons.find((icon) => icon.name == iconType);

  return (
    <Dialog
      classes={{ root: classes.highOrderPopup }}
      open={open}
      onClose={handleClose}
      maxWidth="md"
      {...props}
      //   disableEscapeKeyDown
    >
      <Box sx={{ width: width || '443px' }}>
        <Box
          className={classes.alertBanner}
          sx={{ backgroundColor: bannerColor || '#FFF0BC' }}
        >
          {isCloseIconRequired && (
            <Box
              className={classes.alertCloseIcon}
              onClick={onClose}
              aria-label="close"
            >
              {/* <img src={closeIcon} alt="close" /> */}
              <CloseIcon
                primaryColor="#0A192B"
                sx={{ width: '24px', height: '24px' }}
              />
            </Box>
          )}

          {/* {alertIcons.map((alertIcon)=> <img src={alertIcon.icon} alt="" />)} */}
          {bannerImage && (
            <img
              src={bannerImage}
              alt="image"
              // src={iconToRender?.icon}
              // alt={iconToRender?.name}
              // title={iconToRender?.name}
              className={classes.bannerObject}
              style={{ bottom: bannerBottomPosition || '-20px' }}
            />
          )}
        </Box>
        <Box className={classes.alertBody}>
          {headerContent}
          {descriptionContent && (
            <Box display="flex" justifyContent="center" mt={3}>
              {descriptionContent}
            </Box>
          )}
          {actionButtons && (
            <Box className={classes.alertActions}>{actionButtons}</Box>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
export default CloudPopup;
