import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Box, Button, Input, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

const useStyles = makeStyles()(() => {
  return {
    preview: {
      background: '#fff',
      height: '100%',
      zIndex: 99,
      '& iframe': {
        width: '100%',
        height: 'calc(100vh - 32px)',
        border: '0 none',
      },
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '5px 10px',
      zIndex: 9999,
      boxShadow: '#000000 0 2px 3px -2px',
      '& input': {
        padding: 0,
      },
      '& Button': {
        padding: 0,
        minWidth: 'auto',
        marginLeft: '8px',
      },
      '& svg': {
        width: '16px',
      },
    },
  };
});

export function Browser(props: any) {
  const { leftMenuValue, onLeftMenuChange, onLeftMenuToggle } = props;
  const { classes } = useStyles();

  const [url, setUrl] = useState('https://reactjs.khcodelab.com:3006/');

  const delay = (milliseconds) =>
    new Promise((resolve) => {
      setTimeout(resolve, milliseconds);
    });

  const handleRefresh = async () => {
    const lastStoredUrl = url;
    setUrl('null');
    await delay(100);
    setUrl(lastStoredUrl);
  };

  const handleOpen = () => {
    window.open(url, '_blank');
  };

  return (
    <Box className={classes.preview}>
      <Box className={classes.header}>
        <Box flexGrow={1}>
          <Input
            id={'url'}
            name={'url'}
            fullWidth={true}
            defaultValue={url}
            onChange={(e) => setUrl(e.target.value)}
            sx={{ height: 16 }}
          />
        </Box>
        <Button onClick={handleRefresh}>
          <RefreshOutlinedIcon></RefreshOutlinedIcon>
        </Button>
        <Button onClick={handleOpen}>
          <OpenInNewOutlinedIcon></OpenInNewOutlinedIcon>
        </Button>
      </Box>
      <Box>
        <iframe title={url} allowFullScreen src={url}></iframe>
      </Box>
    </Box>
  );
}

export default Browser;
