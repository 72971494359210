import { Editor, loader } from '@monaco-editor/react';
import { Box } from '@mui/material';
import { memo, useEffect, useRef, useState } from 'react';

import FileTabs from './tabs/file-tabs';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveFile, selectEditorOpenFiles } from '../editor.slice';
import { sendMessage } from '@cloudlabs-frontend/features/socket';
import langMap from 'lang-map';

type NodeModel<T = unknown> = {
  id: number | string;
  parent: number | string;
  text: string;
  type?: string;
  value?: string;
  isActive?: boolean;
};

loader.config({
  paths: {
    vs: 'assets/editor/min/vs',
  },
});

export function MonacoEditor(props: any) {
  const { handleEditorChange, width, selectedFile, height } = props;
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const filesOpened = useSelector(selectEditorOpenFiles) as NodeModel[];
  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
  };
  const [activeFile, setActiveFile] = useState<any>();
  const [language, setLanguage] = useState<string>('javascript');

  // const activeFile = useSelector(selectActiveFile);

  // const joinPaths = (...paths) => {
  //   return paths
  //     .join('/')
  //     .replace(/\/{2,}/g, '/')
  //     .replace(/\/$/, '');
  // };

  const getFileExtension = (filename) => {
    if (!filename) {
      return 'javascript';
    }
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1 || lastDotIndex === 0) {
      return '';
    }
    return filename.slice(lastDotIndex).substring(1);
  };

  useEffect(() => {
    if (filesOpened.length <= 0) {
      setActiveFile(null);
      return;
    }

    const activeFileData =
      filesOpened && filesOpened.find((e: NodeModel) => e.isActive === true);

    if (activeFileData && !activeFileData.value) {
      dispatch(
        sendMessage({
          handler: 'GET_FILE_CONTENT',
          message: { path: activeFileData.id },
        })
      );
      // setActiveFile(null);
      setActiveFile(activeFileData);

      setLanguage(langMap.languages(getFileExtension(activeFileData.text))[0]);
    } else if (activeFileData) {
      setActiveFile(null);
      setActiveFile(activeFileData);
      setLanguage(langMap.languages(getFileExtension(activeFileData.text))[0]);
    }
  }, [filesOpened]);

  useEffect(() => {
    return () => {
      if (editorRef.current) {
        editorRef.current.dispose();
      }
    };
  }, []);

  return (
    <Box
      sx={{
        margin: '0 !important',
        width: '-webkit-fill-available',
        height: '-webkit-fill-available',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <FileTabs activeFile={activeFile} selectedFile />
      {['ico', 'png', 'jpg', 'jpeg'].includes(language) && activeFile?.value ? (
        <Box
          width={width}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          height={'100%'}
        >
          <img src={`data:image/png;base64,${activeFile?.value}`} alt="" />
        </Box>
      ) : (
        activeFile?.value && (
          <Editor
            defaultLanguage={language || 'javascript'}
            defaultValue={''}
            language={language}
            value={activeFile?.value}
            onMount={handleEditorDidMount}
            onChange={(e) => handleEditorChange(e, activeFile)}
            theme="vs-dark"
            width={width}
          />
        )
      )}
    </Box>
  );
}

export default MonacoEditor;
