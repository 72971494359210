// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import Button from '@cloudlabs-frontend/components/core/Button';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import CloudPopup from '@cloudlabs-frontend/components/core/CloudPopup';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import CloudTypography from '@cloudlabs-frontend/components/core/CloudTypography';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import Accordion from '@cloudlabs-frontend/components/core/Accordion';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import Tag, { IndicationTag } from '@cloudlabs-frontend/components/core/Tag';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  TagVariant,
  TagSize,
} from 'libs/common/src/lib/components/core/tag/Tag.interface';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import CloudFroalaEditor from '@cloudlabs-frontend/components/core/CloudFroalaEditor';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Box from '@mui/material/Box/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid/Grid';
import Menu from '@mui/material/Menu/Menu';
import Step from '@mui/material/Step/Step';
import StepButton from '@mui/material/StepButton/StepButton';
import Stepper from '@mui/material/Stepper/Stepper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip/Tooltip';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import wrongImage from 'libs/common/src/lib/components/Assets/png/cloudWrong.png';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import correctImage from 'libs/common/src/lib/components/Assets/png/cloudCorrect.png';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import loaderImage from 'libs/common/src/lib/components/Assets/png/circleLoader.png';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import endLabImage from 'libs/common/src/lib/components/Assets/png/endLab.png';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import attemptsImage from 'libs/common/src/lib/components/Assets/png/attemptsIcon.png';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';

import { ICloudBasedUserLabAsset, ICloudLabAsset } from '../landing/interface';
import {
  getCloudUserLabAsset,
  selectIsLoaded,
  selectIsLoading,
  selectIsValidate,
  selectTestCasesResponse,
  startCloudUserLabAsset,
  stopCloudUserLabAsset,
  validateCloudUserLabAsset,
} from '../landing/slice';
import Timer from './timer';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  WrongCircleRedLargeIcon,
  CheckCircleColorIcon,
  AssignmentColorIcon,
  InstructorColorIcon,
  BookColorIcon,
  DurationColorIcon,
} from '@cloudlabs-frontend/components/core/Iconography';
import { baseStyles } from './BaseStyles';
import { withStyles } from '@mui/styles';
import List from '@mui/material/List/List';
import ListItem from '@mui/material/ListItem/ListItem';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import Configuration from '@cloudlabs-frontend/lib/utils/config/config';
import { Stack } from '@mui/material';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
// interface IDetails {
//     seconds: number
// }
const useStyles = makeStyles()(() => {
  return {
    completed: {
      backgroundColor: 'rgb(51, 171, 63) !important',
      // pointerEvents: 'none',
      // cursor: 'not-allowed',
    },
    active: {
      backgroundColor: 'rgba(10, 25, 43, 1)',
    },
    notCompleted: {
      pointerEvents: 'all',
      // cursor: 'not-allowed',
    },
    notActive: {
      pointerEvents: 'all',
    },

    chip: {
      padding: '12px',
      backgroundColor: '#FFFFFF',
      display: 'inline-flex',
      boxSizing: 'border-box',
      borderRadius: '4px',
      border: '1px solid #CCCCCC',
      height: '40px',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#CCCCCC',
      '.MuiSvgIcon-root': {
        color: '#6B7580',
        cursor: 'pointer',
        paddingLeft: '12px',
      },
    },
    errorAccordion: {
      borderRadius: '0 !important',
      border: '0 !important',
      borderBottom: '1px solid #F3F3F3 !important',
      '&::before': {
        display: 'none',
      },
      '& > .MuiAccordionSummary-root': {
        padding: '0 !important',
        '& > .MuiAccordionSummary-content': {
          margin: '0 !important',
        },
      },
      '& .MuiAccordionDetails-root': {
        padding: '0 !important',
      },
      '& .MuiDivider-root': {
        display: 'none',
      },
      '& .MuiAccordionSummary-expandIconWrapper': {
        marginLeft: '5px',
      },
      '& .MuiCollapse-wrapper': {
        marginBottom: '18px',
      },
    },
    errorSummary: {
      backgroundColor: 'rgba(245, 99, 16, 0.1)',
      borderRadius: 12,
      padding: '12px 16px',
      marginBottom: '5px',
      textAlign: 'left',
    },
    redColor: {
      color: '#F56310',
    },
    loaderImage: {
      animation: '$spin 2s linear infinite',
      backgroundColor: 'transparent',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(360deg)',
      },
      '100%': {
        transform: 'rotate(0deg)',
      },
    },
    gridContainer: {
      width: '100%',
      background: '#FFFFFF',
      border: '1px solid #EEEEEE',
      borderRadius: '4px',
      padding: '40px 34px',
      marginBottom: '52px',
      '& > div:first-child': {
        border: 'none !important',
      },
    },
    gridItem: {
      borderLeft: '1px solid #EEEEEE',
      padding: '0 10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    gridItemHeading: {
      // marginBottom: '8px',
    },
    tabHeader: {
      fontSize: '14px',
      fontWeight: '300',
      lineHeight: '16px',
      textTransform: 'capitalize',
      '&.Mui-selected': {
        fontWeight: '700',
      },
    },
    tabsStyle: {
      '& .MuiTab-root': {
        fontSize: '14px',
        fontWeight: '300',
        lineHeight: '16px',
        textTransform: 'capitalize',
      },
      '& .Mui-selected': {
        fontWeight: '700',
      },
    },
    buttonRed: {
      backgroundColor: '#D41900',
    },
    listStyle: {
      display: 'list-item',
      listStyleType: 'number',
      padding: '0 10px 0 4px',
      '& .MuiTypography-root': {
        lineHeight: '190%',
      },
    },
  };
});

const ValidationDialog = (props: any) => {
  const {
    dialogOpen,
    isValidatedSuccessfully,
    handleClose,
    isAllStepsValidated,
    handleNext,
  } = props;

  return (
    <div>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {isValidatedSuccessfully ? (
            <CheckCircleIcon></CheckCircleIcon>
          ) : (
            <CancelIcon></CancelIcon>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isValidatedSuccessfully ? 'Successfull' : 'Error'}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            {isValidatedSuccessfully
              ? 'Step has been validated'
              : 'Retry After going through the step'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isAllStepsValidated ? (
            <Button onClick={handleClose} autoFocus>
              Completed
            </Button>
          ) : (
            <Button onClick={handleNext} autoFocus>
              Continue
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

const CloudStepper = (props: any) => {
  const {
    activeStep,
    classes,
    cloudUserLabAsset,
    validateExercise,
    steps,
    handleStep,
    labType,
    sendMessageToIframeChild,
  } = props;

  const { status, cloudLabAsset } = cloudUserLabAsset;

  const validateStatus = useSelector(selectIsValidate);

  const testCaseResponse = useSelector(selectTestCasesResponse) || [];

  const checkIfCompleted = (step) => {
    if (testCaseResponse.length <= 0) {
      return false;
    }
    const failedTests = testCaseResponse.findIndex(
      (e) => e.step === step && e.status !== 'passed'
    );

    return failedTests < 0 ? true : false;
  };

  return (
    <Box
      sx={{
        px: 10,
        py: 3.5,
        borderTop: '1px solid #EDEBE7',
        height: '66px',
      }}
      display={'flex'}
      alignItems={'center'}
    >
      <Grid
        container
        direction={'row'}
        justifyContent="space-between"
        alignItems={'center'}
      >
        <Grid item sx={{ pt: 0 }}>
          {labType !== 'SUBMIT' && (
            <Stepper
              nonLinear
              activeStep={activeStep}
              sx={{
                '.MuiStepConnector-root': {
                  top: 0,
                  display: 'none',
                },
              }}
            >
              {steps.map((stepData, index) => (
                <Step
                  key={stepData.step}
                  completed={checkIfCompleted(stepData.step)}
                  active={activeStep === index}
                  sx={{
                    px: 3.5,
                    py: 2.5,
                    mr: 3,
                    lineHeight: '10px',
                    border: '1px solid rgb(219 218 215)',
                    borderRadius: '4px',
                  }}
                  className={`${checkIfCompleted(stepData.step)
                    ? classes.completed
                    : classes.notCompleted
                    } ${activeStep - 1 === index
                      ? classes.active
                      : classes.notActive
                    }`}
                // classes={{ completed: classes.completed }}
                >
                  <StepButton color="inherit" onClick={() => handleStep(index)}>
                    <CloudTypography
                      textVariant="h12WithSemiBold"
                      color={'rgb(219 218 215)'}
                    >
                      {stepData.step}
                    </CloudTypography>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          )}
        </Grid>
        <Grid item>
          <Box>
            {labType !== 'SUBMIT' ? (
              <>
                {testCaseResponse?.length <= 0 ? (
                  <Button
                    onClick={() => validateExercise(activeStep)}
                    btnSize="small"
                    disabled={
                      !status || (status && status.toLowerCase() !== 'running')
                    }
                    loading={validateStatus}
                    loadingText="Validating"
                  >
                    Validate Step
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      if (activeStep === steps?.length) {
                        sendMessageToIframeChild('nextAsset');
                      } else {
                        handleStep(activeStep);
                      }
                    }}
                    btnSize="small"
                    // disabled={activeStep === steps?.length}
                    loading={validateStatus}
                    loadingText="Validating"
                  >
                    Next Step
                  </Button>
                )}
              </>
            ) : (
              <Button
                onClick={() => validateExercise()}
                btnSize="small"
                sx={{ mr: 1 }}
                disabled={
                  !status || (status && status.toLowerCase() !== 'running')
                }
                loading={validateStatus}
                loadingText="Submitting"
              >
                Submit Exercise
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const CloudCredentials = (props: any) => {
  const { classes } = useStyles();
  const { cloudUserLabAsset, isIframeBased } = props;
  const { accessKey, secretKey, password, userName, url } = cloudUserLabAsset;

  const [isCopied, setIsCopied] = useState(false);

  const navigateToConsole = () => {
    window.open(url, '_blank');
  };

  const copyToClipBoard = (text) => {
    setIsCopied(true);
    navigator.clipboard.writeText(text);
  };

  const handleMouseMove = () => {
    if (isCopied) {
      setIsCopied(false);
    }
  };

  const sendMessageToIframeChild = (action, message) => {
    setIsCopied(true);
    window.parent.postMessage(
      {
        type: 'ACTION',
        action,
        message,
      },
      '*'
    );
  };

  return (
    <Box onMouseMove={() => handleMouseMove()}>
      <Box>
        <CloudTypography textVariant="h9WithMedium">User Name</CloudTypography>
        <Box className={classes.chip} sx={{ mt: 2 }}>
          <CloudTypography textVariant="h9WithMedium">
            {accessKey ? userName : `${userName}@upgraddevopsgmail.onmicrosoft.com`}
          </CloudTypography>
          <Tooltip
            title={
              isCopied ? (
                <Box display={'flex'} alignItems="center">
                  <CheckCircleOutlinedIcon
                    sx={{ height: '12px' }}
                  ></CheckCircleOutlinedIcon>
                  Copied
                </Box>
              ) : (
                'Click to Copy'
              )
            }
            placement="right"
          >
            <ContentCopyOutlinedIcon
              onClick={() =>
                isIframeBased
                  ? sendMessageToIframeChild('copyToClipBoard', accessKey ? userName : `${userName}@upgraddevopsgmail.onmicrosoft.com`)
                  : copyToClipBoard(accessKey ? userName : `${userName}@upgraddevopsgmail.onmicrosoft.com`)
              }
            ></ContentCopyOutlinedIcon>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ pt: 4 }}>
        <CloudTypography textVariant="h9WithMedium">Password</CloudTypography>
        <Box className={classes.chip} sx={{ mt: 2 }}>
          <CloudTypography textVariant="h9WithMedium">
            {password}
          </CloudTypography>
          <Tooltip
            title={
              isCopied ? (
                <Box display={'flex'} alignItems="center">
                  <CheckCircleOutlinedIcon
                    sx={{ height: '12px' }}
                  ></CheckCircleOutlinedIcon>
                  Copied
                </Box>
              ) : (
                'Click to Copy'
              )
            }
            placement="right"
          >
            <ContentCopyOutlinedIcon
              onClick={() =>
                isIframeBased
                  ? sendMessageToIframeChild('copyToClipBoard', password)
                  : copyToClipBoard(password)
              }
            ></ContentCopyOutlinedIcon>
          </Tooltip>
        </Box>
      </Box>
      {accessKey && (
        <>
          <Box sx={{ pt: 4 }}>
            <CloudTypography textVariant="h9WithMedium">Access Key</CloudTypography>
            <Box className={classes.chip} sx={{ mt: 2 }}>
              <CloudTypography textVariant="h9WithMedium">
                {accessKey}
              </CloudTypography>
              <Tooltip
                title={
                  isCopied ? (
                    <Box display={'flex'} alignItems="center">
                      <CheckCircleOutlinedIcon
                        sx={{ height: '12px' }}
                      ></CheckCircleOutlinedIcon>
                      Copied
                    </Box>
                  ) : (
                    'Click to Copy'
                  )
                }
                placement="right"
              >
                <ContentCopyOutlinedIcon
                  onClick={() =>
                    isIframeBased
                      ? sendMessageToIframeChild('copyToClipBoard', accessKey)
                      : copyToClipBoard(accessKey)
                  }
                ></ContentCopyOutlinedIcon>
              </Tooltip>
            </Box>
          </Box>
          <Box sx={{ pt: 4 }}>
            <CloudTypography textVariant="h9WithMedium">Secret Key</CloudTypography>
            <Box className={classes.chip} sx={{ mt: 2 }}>
              <CloudTypography textVariant="h9WithMedium">
                {secretKey}
              </CloudTypography>
              <Tooltip
                title={
                  isCopied ? (
                    <Box display={'flex'} alignItems="center">
                      <CheckCircleOutlinedIcon
                        sx={{ height: '12px' }}
                      ></CheckCircleOutlinedIcon>
                      Copied
                    </Box>
                  ) : (
                    'Click to Copy'
                  )
                }
                placement="right"
              >
                <ContentCopyOutlinedIcon
                  onClick={() =>
                    isIframeBased
                      ? sendMessageToIframeChild('copyToClipBoard', secretKey)
                      : copyToClipBoard(secretKey)
                  }
                ></ContentCopyOutlinedIcon>
              </Tooltip>
            </Box>
          </Box>
        </>
      )}
      <Box sx={{ pt: 6 }} textAlign="center">
        <Button btnSize="small" sx={{ mr: 2 }} onClick={navigateToConsole}>
          Open Console{' '}
        </Button>
      </Box>
    </Box>
  );
};

const CloudInstructions = (props: any) => {
  const { activeStep, cloudUserLabAsset, labType } = props;

  const { cloudLabAsset } = cloudUserLabAsset;

  const { instructions } = cloudLabAsset;

  const instructionData = instructions.find(
    (instruction) => instruction.step === activeStep
  );

  const { instruction: currentInstruction = null } = instructionData || {};
  return (
    <Box>
      {labType !== 'SUBMIT' ? (
        <Box sx={{ px: 10, py: 6 }}>
          {instructions.length >= 0 && activeStep <= instructions.length ? (
            <Box>
              <CloudTypography textVariant="h9WithSemiBold">
                Step {activeStep}
              </CloudTypography>

              <CloudFroalaEditor
                model={currentInstruction}
                viewOnly={true}
                name="froalaEditorView"
              ></CloudFroalaEditor>

              {/* <FroalaEditorView
                config={{
                  key: Configuration.froalaKey,
                }}
                model={currentInstruction}
              ></FroalaEditorView> */}
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <CloudTypography textVariant="text">
                {instructions.length <= 0
                  ? 'No Steps Found'
                  : 'All Steps Finished'}
              </CloudTypography>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={{ px: 10, py: 6 }}>
          {instructions.map(({ instruction, step }) => (
            <Box key={`${step}-instruction`}>
              <CloudTypography textVariant="h9WithSemiBold">
                Step {step}
              </CloudTypography>

              <FroalaEditorView
                config={{
                  key: Configuration.froalaKey,
                }}
                model={instruction}
              ></FroalaEditorView>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

const ExerciseLandingPage = (props: any) => {
  const { subDomain } = props;
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoaded);

  const startExercise = () => {
    dispatch(startCloudUserLabAsset({ where: { slug: subDomain } }));
  };
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Button
        btnSize="small"
        sx={{ mr: 1 }}
        onClick={startExercise}
        loading={isLoading}
        loadingText={'Starting the lab'}
      >
        Start Lab
      </Button>
    </Box>
  );
};

const CloudDetailsTopSection = (props: any) => {
  const { cloudUserLabAsset, subDomain, isIframeBased, preview } = props;
  const dispatch = useDispatch();
  const {
    startedAt = null,
    creditsInSeconds = null,
    status = 'stopped',
    cloudLabAsset = {},
    attempts,
    attemptsLeft,
  } = cloudUserLabAsset;

  const { name } = cloudLabAsset as ICloudLabAsset;
  const truncatedText = name.length > 100 ? name.slice(0, 100) + '...' : name;

  const [credits, setCredits] = React.useState(0);
  const [showCrdentials, setShowCrdentials] =
    React.useState<null | HTMLElement>(null);
  const [endLabPopupOpen, setEndLabPopupOpen] = React.useState(false);

  const isLoading = useSelector(selectIsLoading);

  const handleClose = () => {
    setShowCrdentials(null);
  };
  const open = Boolean(showCrdentials);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setShowCrdentials(event.currentTarget);
  };

  const timerCleared = () => {
    const startDate = new Date(startedAt).getTime();
    const endDate = new Date().getTime();
    const seconds = (endDate - startDate) / 1000;
    const secondsLeft = Math.round(creditsInSeconds - seconds);
    setTimeout(() => {
      dispatch(getCloudUserLabAsset({ where: { slug: subDomain } }));
    }, secondsLeft * 1000);
  };

  const startExercise = () => {
    dispatch(startCloudUserLabAsset({ where: { slug: subDomain } }));
  };

  const stopExercise = () => {
    setEndLabPopupOpen(false);
    dispatch(stopCloudUserLabAsset({ where: { slug: subDomain } }));
  };

  useEffect(() => {
    if (startedAt) {
      const startDate = new Date(startedAt).getTime();
      const endDate = new Date().getTime();
      const seconds = (endDate - startDate) / 1000;
      setCredits(Math.round(creditsInSeconds - seconds));
    }
  }, [startedAt]);

  const handleVisibilityChange = () => {
    if (startedAt) {
      const startDate = new Date(startedAt).getTime();
      const endDate = new Date().getTime();
      const seconds = (endDate - startDate) / 1000;
      setCredits(Math.round(creditsInSeconds - seconds));
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const sendMessageToIframeChild = (action, message = null) => {
    window.parent.postMessage(
      {
        type: 'ACTION',
        action,
        message,
      },
      '*'
    );
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        px: 6,
        py: 3.5,
        borderRadius: '2px',
      }}
    >
      <>
        <Grid
          container
          spacing={6}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            item
            sx={{
              width:
                status && status.toLowerCase() === 'running' ? '30%' : '50%',
            }}
          >
            {' '}
            <CloudTypography
              textVariant="h8WithBold"
              sx={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {truncatedText}
            </CloudTypography>
          </Grid>
          <Grid item>
            <Grid container spacing={6} alignItems="center">
              {status && status.toLowerCase() === 'running' && (
                <Grid item>
                  <Timer
                    seconds={creditsInSeconds}
                    secondsLeft={credits}
                    timerCleared={timerCleared}
                    cloudUserLabAsset={cloudUserLabAsset}
                  ></Timer>
                </Grid>
              )}
              <Grid
                item
                display={'flex'}
                alignContent={'center'}
                justifyContent={'center'}
              >
                <img src={attemptsImage} alt="loader" />
                <CloudTypography textVariant="h8" sx={{ ml: 2 }}>
                  {attemptsLeft}/{attempts} attempts
                </CloudTypography>
              </Grid>
              {status && status.toLowerCase() === 'running' ? (
                <Grid item>
                  <Button btnSize="small" sx={{ mr: 4 }} onClick={handleClick}>
                    Credentials{' '}
                    <KeyboardArrowDownOutlinedIcon
                      fontSize="medium"
                      sx={{ pl: 2.5, height: '15px' }}
                    ></KeyboardArrowDownOutlinedIcon>
                  </Button>
                  <Button
                    btnSize="small"
                    // onClick={stopExercise}
                    onClick={() => {
                      isIframeBased
                        ? sendMessageToIframeChild('openEndLabPopup')
                        : setEndLabPopupOpen(true);
                    }}
                    loading={isLoading}
                    loadingText="Ending"
                    color="error"
                  >
                    End Lab
                    <CloseOutlinedIcon
                      fontSize="medium"
                      sx={{ pl: 2.5, height: '15px' }}
                    ></CloseOutlinedIcon>
                  </Button>
                </Grid>
              ) : (
                <Grid item>
                  <Button
                    btnSize="small"
                    onClick={startExercise}
                    loading={isLoading}
                    loadingText="Creating Lab"
                    disabled={attemptsLeft <= 0 || preview}
                  >
                    Start Lab
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Menu
          anchorEl={showCrdentials}
          id="account-menu"
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,

            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(8px -2px 31px rgba(0, 0, 0, 0.16))',
              mt: 3,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Box sx={{ px: 4, py: 6 }}>
            <CloudCredentials
              cloudUserLabAsset={cloudUserLabAsset}
              isIframeBased={isIframeBased}
            ></CloudCredentials>
          </Box>
        </Menu>
      </>
      <CloudPopup
        width="564px"
        open={endLabPopupOpen}
        bannerImage={endLabImage}
        onClose={() => setEndLabPopupOpen(false)}
        isCloseIconRequired={true}
        bannerColor={'#FFF0BC'}
        descriptionContent={
          <Box sx={{ width: '100%' }}>
            <Box sx={{ pb: 4 }}>
              <CloudTypography textVariant="h7WithBold" sx={{ pb: 3 }}>
                End Lab
              </CloudTypography>
              <CloudTypography textVariant="h9WithLight" color="#6B7580">
                Are you sure you want to end lab? Ending will record your
                progress as one attempt
              </CloudTypography>
            </Box>
            <Box>
              <Button
                btnSize="small"
                btnVariant="secondary"
                onClick={() => setEndLabPopupOpen(false)}
                sx={{ mr: 3 }}
              >
                Stay
              </Button>
              <Button btnSize="small" onClick={stopExercise}>
                Yes, end lab
              </Button>
            </Box>
          </Box>
        }
      ></CloudPopup>
    </Box>
  );
};

//Skills map function starts here
const loadSkills = (data) => {
  if (data?.length <= 3) {
    return (
      <Box marginTop={2.25}>
        <Stack direction="row" spacing={4}>
          {data.map((subSkill: any, sIndex: number) => (
            <Box key={`${sIndex}-subskill`}>
              {subSkill.length <= 20 ? (
                <Tag
                  paddingSize={TagSize.large}
                  variant={TagVariant.roundedSquare}
                  label={
                    <CloudTypography textVariant="h11">
                      {subSkill}
                    </CloudTypography>
                  }
                />
              ) : (
                <Tooltip
                  title={subSkill}
                  placement="right"
                  arrow
                  enterDelay={300}
                  leaveDelay={200}
                >
                  <Tag
                    paddingSize={TagSize.large}
                    variant={TagVariant.roundedSquare}
                    label={
                      <CloudTypography textVariant="h11">
                        {subSkill}
                      </CloudTypography>
                    }
                  />
                </Tooltip>
              )}
            </Box>
          ))}
        </Stack>
      </Box>
    );
  } else if (data?.length > 3) {
    return (
      <Box marginTop={2.25}>
        <Stack direction="row" spacing={4} alignItems="center">
          {data.slice(0, 3).map((subSkill: any, sIndex: number) => (
            <Box key={`${sIndex}-subskill`}>
              {subSkill.length <= 20 ? (
                <Tag
                  paddingSize={TagSize.large}
                  variant={TagVariant.roundedSquare}
                  label={
                    <CloudTypography textVariant="h11">
                      {subSkill}
                    </CloudTypography>
                  }
                />
              ) : (
                <Tooltip
                  title={subSkill}
                  placement="right"
                  arrow
                  enterDelay={300}
                  leaveDelay={200}
                >
                  <Tag
                    paddingSize={TagSize.large}
                    variant={TagVariant.roundedSquare}
                    label={
                      <CloudTypography textVariant="h11">
                        {subSkill}
                      </CloudTypography>
                    }
                  />
                </Tooltip>
              )}
            </Box>
          ))}
          <Tooltip
            title={data.slice(3).map((info: any, index: number) => (
              <Box key={`${index}-tool`} display={'flex'} flexWrap={'wrap'}>
                {info}
              </Box>
            ))}
            placement="right"
            arrow
            enterDelay={300}
            leaveDelay={200}
          >
            <Tag
              paddingSize={TagSize.large}
              variant={TagVariant.roundedSquare}
              icon={
                <AddIcon
                  sx={{ height: 17 }}
                  style={{ marginRight: -2, marginLeft: -7 }}
                />
              }
              label={data.slice(3).length}
            />
          </Tooltip>
        </Stack>
      </Box>
    );
  }
};
//Ends

const CloudDetailsMiddleSection = (props: any) => {
  const {
    activeStep,
    handleNext,
    cloudUserLabAsset,
    validateExercise,
    handleStep,
    labType,
    classes,
    iframeParentData,
    isIframeBased,
    sendMessageToIframeChild,
  } = props;
  const [currentTab, setCurrentTab] = useState(0);

  const { cloudLabAsset = {}, status } =
    (cloudUserLabAsset as ICloudBasedUserLabAsset) || {};

  useEffect(() => {
    if (status && status.toLowerCase() === 'running') {
      setCurrentTab(1);
    }
  }, [status]);

  const { instructions = [] } = cloudLabAsset as ICloudLabAsset;
  function _renderRelevantTab() {
    switch (currentTab) {
      case 0:
        return (
          <Box
            sx={{
              height: 'calc(100% - 51px)',
              px: 10,
              py: 6,
              overflow: 'auto',
            }}
          >
            {isIframeBased && iframeParentData && (
              <>
                <Grid container className={classes.gridContainer}>
                  <Grid item md={3} className={classes.gridItem}>
                    <Box mr={3}>
                      <AssignmentColorIcon />
                    </Box>
                    <Box>
                      <CloudTypography
                        className={classes.gridItemHeading}
                        textVariant="h8WithLight"
                      >
                        Learning Duration
                      </CloudTypography>
                      <CloudTypography textVariant="h6WithBold">
                        {iframeParentData?.learningDuration}
                      </CloudTypography>
                    </Box>
                  </Grid>
                  <Grid item md={3} className={classes.gridItem}>
                    <Box mr={3}>
                      <DurationColorIcon />
                    </Box>
                    <Box>
                      <CloudTypography
                        className={classes.gridItemHeading}
                        textVariant="h8WithLight"
                      >
                        Submit By Days
                      </CloudTypography>
                      <CloudTypography textVariant="h6WithBold">
                        {iframeParentData?.submitByDays}
                      </CloudTypography>
                    </Box>
                  </Grid>
                  <Grid item md={3} className={classes.gridItem}>
                    <Box mr={3}>
                      <BookColorIcon />
                    </Box>
                    <Box>
                      <CloudTypography
                        className={classes.gridItemHeading}
                        textVariant="h8WithLight"
                      >
                        Maximum Marks
                      </CloudTypography>
                      <CloudTypography textVariant="h6WithBold">
                        {iframeParentData?.marks}
                      </CloudTypography>
                    </Box>
                  </Grid>

                  <Grid item md={3} className={classes.gridItem}>
                    <Box mr={3}>
                      <InstructorColorIcon />
                    </Box>
                    <Box>
                      <CloudTypography
                        className={classes.gridItemHeading}
                        textVariant="h8WithLight"
                      >
                        Pass Percentage
                      </CloudTypography>
                      <CloudTypography textVariant="h6WithBold">
                        {iframeParentData?.passPercentage}%
                      </CloudTypography>
                    </Box>
                  </Grid>
                </Grid>

                <Box mb={6} className={classes.container}>
                  <Box mb={1.25}>
                    <CloudTypography
                      textVariant="h6WithBold"
                      sx={{ marginBottom: 4, marginTop: 8 }}
                    >
                      Skill
                    </CloudTypography>
                  </Box>

                  <Box>{loadSkills(iframeParentData?.skills)}</Box>
                  <Box mb={1.25}>
                    <CloudTypography
                      textVariant="h6WithBold"
                      sx={{ marginBottom: 4, marginTop: 8 }}
                    >
                      Sub Skill
                    </CloudTypography>
                  </Box>

                  <Box>{loadSkills(iframeParentData?.subSkills)}</Box>
                </Box>
              </>
            )}
            {isIframeBased && iframeParentData && (
              <CloudTypography textVariant="h6WithBold" sx={{ pb: 4 }}>
                Pre-Requisites
              </CloudTypography>
            )}
            {/* <FroalaEditorView
              config={{
                key: Configuration.froalaKey,
              }}
              model={`<div>1. Click on the<strong>&nbsp;Start Lab</strong> button. Once the Lab is started, you will be provided with the credentials</div><div>2. Click on <strong>Open Console</strong> Button to open the Lab window. Follow the steps in the console and validate them on this tab.&nbsp;</div><div><strong>Note: </strong>Lab will be having limited access time and limited number of attempts. Make sure you validate all the steps before you run out of attempts.</div>`}
            ></FroalaEditorView> */}
            <Box sx={{ ml: 5 }}>
              <List
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                  pb: 0,
                }}
              >
                <ListItem className={classes.listStyle}>
                  <CloudTypography textVariant="h9">
                    Click on the <b>Start Lab</b> button. Once the Lab is
                    started, you will be provided with the credentials
                  </CloudTypography>
                </ListItem>

                <ListItem className={classes.listStyle}>
                  <CloudTypography textVariant="h9">
                    Click on <b>Open Console</b> Button to open the Lab window.
                    Follow the steps in the console and validate them on this
                    tab. Note: Lab will be having limited access time and
                    limited number of attempts. Make sure you validate all the
                    steps before you run out of attempts.
                  </CloudTypography>
                </ListItem>
              </List>
            </Box>
            <CloudTypography textVariant="h9">
              <b>Note:</b> Lab will be having limited access time and limited
              number of attempts. Make sure you validate all the steps before
              you run out of attempts.
            </CloudTypography>
          </Box>
        );
      case 1:
        return (
          <Box sx={{ height: 'calc(100% - 49px)', overflow: 'hidden' }}>
            <Grid container sx={{ height: '100%' }}>
              <Grid
                sx={{ height: 'calc(100% - 66px)', overflow: 'auto' }}
                item
                container
                xs={12}
                direction="column"
                justifyContent="flex-start"
              >
                <CloudInstructions
                  activeStep={activeStep}
                  handleNext={handleNext}
                  cloudUserLabAsset={cloudUserLabAsset}
                  labType={labType}
                ></CloudInstructions>
              </Grid>
              <Grid item xs={12}>
                <CloudStepper
                  validateExercise={validateExercise}
                  activeStep={activeStep}
                  classes={classes}
                  steps={instructions}
                  handleStep={handleStep}
                  cloudUserLabAsset={cloudUserLabAsset}
                  labType={labType}
                  sendMessageToIframeChild={sendMessageToIframeChild}
                ></CloudStepper>
              </Grid>
            </Grid>
          </Box>
        );
      case 2:
        return 'test';
    }
  }

  return (
    <Box sx={{ height: 'calc(100vh - 65px)' }}>
      <Tabs
        sx={{ mx: 6, borderBottom: '1px solid #EEEEEE' }}
        value={currentTab}
        indicatorColor="primary"
        onChange={(tab, value) => {
          setCurrentTab(value);
        }}
        className={classes.tabsStyle}
      >
        <Tab
          label={
            isIframeBased && iframeParentData ? 'Details' : 'Pre-Requisites'
          }
          key="Pre-Requisites"
        />
        <Tab key="lab-steps" label="Lab Steps" />
      </Tabs>
      {_renderRelevantTab()}
    </Box>
  );
};

const DetilsComponent = (props: any) => {
  const { cloudUserLabAsset, subDomain, preview } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const [isValidated, setIsValidated] = React.useState(false);
  const [isValidating, setIsValidating] = React.useState(false);
  const [expandedDetails, setExpandedDetails] = React.useState<boolean>(false);
  const [isOpenTestCaseModel, setOpenTestCaseModel] = React.useState(false);
  const [popupTests, setPopupTests] = React.useState([]);
  const [listenerAttached, setListenerAttached] =
    React.useState<boolean>(false);

  const [labType, setLabType] = React.useState<string>('VALIDATE');

  const [isIframeBased, setIsIframeBased] = React.useState<boolean>(false);
  const [iframeParentData, setIframeParentData] = React.useState<{}>(null);
  const [isOtherAssetRunning, setIsOtherAssetRunning] =
    React.useState<boolean>(false);
  const testCaseResponse = useSelector(selectTestCasesResponse);

  const { cloudLabAsset = {}, otherRunningAsset = null } =
    cloudUserLabAsset as ICloudBasedUserLabAsset;

  const { instructions = [] } = cloudLabAsset as ICloudLabAsset;

  const totalSteps = () => {
    return instructions.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleStep = (index) => {
    setActiveStep(index);
  };

  useEffect(() => console.log(activeStep), [activeStep]);

  const handleNext = () => {
    setActiveStep(activeStep);
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? instructions.findIndex((step, i) => !(i in completed))
        : activeStep + 1;

    setOpenTestCaseModel(false);
    setCompleted({ ...completed, [activeStep]: true });
    setActiveStep(newActiveStep);
  };

  const validateExercise = (activeStep) => {
    setIsValidated(true);
    setIsValidating(true);
    dispatch(
      validateCloudUserLabAsset({
        where: { slug: subDomain, step: activeStep },
      })
    );
  };

  const handleExpand = (code, expanded) => {
    setExpandedDetails(code);
  };

  const checkIfCompleted = (step) => {
    if (step < 0 || !testCaseResponse) {
      return false;
    }
    const failedTests = testCaseResponse.findIndex(
      (e) => e.step === step && e.status !== 'passed'
    );
    return failedTests > -1 ? false : true;
  };

  const attachListener = () => {
    if (listenerAttached) {
      return;
    }

    setListenerAttached(true);
    if (window && window.parent) {
      window.parent.postMessage(
        {
          type: 'LOADED',
          message: 'LOADED',
        },
        '*'
      );
    }

    window.addEventListener('message', listenToMessages);
  };

  const stopExercise = (slug = null) => {
    dispatch(
      stopCloudUserLabAsset({ where: { slug: slug ? slug : subDomain } })
    );
    setIsOtherAssetRunning(false);
  };

  const navigateTo = (slug) => {
    window.location.href = window.location.href.replace(subDomain, slug);
  };

  const listenToMessages = (event) => {
    const message = event.data;
    if (message?.data?.type === 'ACTION') {
      switch (message?.data?.action) {
        case 'iframe':
          setIsIframeBased(true);
          break;
        case 'nextStep':
          handleNext();
          break;
        case 'stopLab':
          stopExercise(message?.data?.message);
          break;
        case 'assignment':
        case 'project':
          setLabType('SUBMIT');
          break;
        case 'iframeParentData':
          setIframeParentData(message?.data?.message);
          break;
        case 'navigateTo':
          navigateTo(message?.data?.message);
          break;
        default:
          break;
      }
    }
  };

  const sendMessageToIframeChild = (action, message = null) => {
    window.parent.postMessage(
      {
        type: 'ACTION',
        action,
        message,
      },
      '*'
    );
  };

  useEffect(() => {
    console.log(cloudUserLabAsset);
  }, [cloudUserLabAsset]);

  useEffect(() => {
    attachListener();

    return () => {
      window.removeEventListener('message', listenToMessages);
    };
  }, []);

  useEffect(() => {
    if (otherRunningAsset) {
      if (!isIframeBased) {
        setIsOtherAssetRunning(true);
      } else {
        sendMessageToIframeChild('otherRunningAsset', otherRunningAsset);
      }
    }
  }, [otherRunningAsset]);

  useEffect(() => {
    if (testCaseResponse) {
      if (isValidated) {
        setIsValidating(false);
        const tests = testCaseResponse.filter((e) => e.step === activeStep + 1);
        setExpandedDetails(false);
        setPopupTests(tests);
        if (!isIframeBased) {
          setOpenTestCaseModel(true);
        }
        setIsValidated(false);
        if (isIframeBased) {
          if (labType !== 'SUBMIT') {
            if (window && window.parent) {
              window.parent.postMessage(
                {
                  type: 'TEST_CASES',
                  message: JSON.stringify({
                    tests,
                    isCompleted: checkIfCompleted(activeStep + 1),
                    isLastStep: isLastStep(),
                  }),
                },
                '*'
              );
            }
          } else {
            sendMessageToIframeChild('REPORT_WAIT_POPUP');
          }
        }
      } else {
        // const tests = testCaseResponse.filter((e) => e.step === activeStep + 1);
        // setPopupTests(tests);
        const failedTests = testCaseResponse.findIndex(
          (testCaseRes) => testCaseRes.status !== 'passed'
        );

        if (failedTests !== -1) {
          setActiveStep(failedTests < 1 ? 0 : failedTests - 1);
        } else {
          setActiveStep(0);
        }
      }
    }
  }, [testCaseResponse]);

  return (
    <Box sx={{ backgroundColor: '#fff' }}>
      {instructions.length > -1 && (
        <>
          <CloudDetailsTopSection
            subDomain={subDomain}
            cloudUserLabAsset={cloudUserLabAsset}
            isIframeBased={isIframeBased}
            preview={preview}
          ></CloudDetailsTopSection>

          <CloudDetailsMiddleSection
            activeStep={activeStep + 1}
            handleNext={handleNext}
            handleStep={handleStep}
            instructions={instructions}
            cloudUserLabAsset={cloudUserLabAsset}
            validateExercise={validateExercise}
            labType={labType}
            classes={classes}
            isIframeBased={isIframeBased}
            iframeParentData={iframeParentData}
            sendMessageToIframeChild={sendMessageToIframeChild}
          ></CloudDetailsMiddleSection>
        </>
      )}
      {/* <Button onClick={() => setOpenTestCaseModel(false)}>Close</Button> */}
      <CloudPopup
        width="564px"
        open={isOpenTestCaseModel}
        bannerImage={
          checkIfCompleted(activeStep + 1) ? correctImage : wrongImage
        }
        onClose={() => setOpenTestCaseModel(false)}
        isCloseIconRequired={true}
        bannerColor={checkIfCompleted(activeStep + 1) ? '#BDFFE1' : '#FFF0BC'}
        descriptionContent={
          <Box sx={{ width: '100%' }}>
            <Box>
              {checkIfCompleted(activeStep + 1) ? (
                <Box sx={{ pb: 4 }}>
                  <CloudTypography textVariant="h6WithBold">
                    Congratulations
                  </CloudTypography>
                  <CloudTypography textVariant="h9WithLight" color="#6B7580">
                    You have successfully completed
                    <span>
                      {' '}
                      {activeStep + 1} / {instructions.length} steps.
                    </span>
                  </CloudTypography>
                </Box>
              ) : (
                <Box sx={{ pb: 4 }}>
                  <CloudTypography textVariant="h6WithBold">
                    Uh oh! Some test cases for Step 1 did not pass.
                  </CloudTypography>
                  <CloudTypography textVariant="h9WithLight" color="#6B7580">
                    Please check and fix the following:
                  </CloudTypography>
                </Box>
              )}
            </Box>
            {popupTests.map((popupTest: any, index: number) => (
              <Box key={`${index}-box`}>
                {popupTest.status === 'failed' ? (
                  <Box>
                    <Accordion
                      onToggleChange={handleExpand}
                      expandedStatusOrId={expandedDetails}
                      key={`${index}-error`}
                      id={`${index}-popup-test`}
                      className={classes.errorAccordion}
                      summary={
                        <Box
                          display={'flex'}
                          justifyContent={'space-between'}
                          alignItems={'start'}
                          padding={0}
                        >
                          <Box sx={{ mr: 3 }}>
                            <WrongCircleRedLargeIcon />
                          </Box>
                          <CloudTypography textVariant="h9" color="#6B7580">
                            {popupTest.name}
                          </CloudTypography>
                        </Box>
                      }
                    >
                      <CloudTypography
                        textVariant="h8"
                        className={classes.errorSummary}
                      >
                        <span className={classes.redColor}>Error: </span>
                        <span>{popupTest.failureMessages}</span>
                      </CloudTypography>
                    </Accordion>
                  </Box>
                ) : (
                  <Box key={`${index}-passed`} py={2}>
                    <Box display="flex" alignItems="start">
                      <Box sx={{ mr: 3 }}>
                        <CheckCircleColorIcon />
                      </Box>
                      <CloudTypography textVariant="h9" ml={2}>
                        {popupTest.name}
                      </CloudTypography>
                    </Box>
                  </Box>
                )}
              </Box>
            ))}
            <Box>
              {!isLastStep() && checkIfCompleted(activeStep + 1) && (
                <Button btnSize="small" onClick={handleNext}>
                  Next Step
                </Button>
              )}

              {isLastStep() && checkIfCompleted(activeStep + 1) && (
                <Button btnSize="small">Next Asset</Button>
              )}
            </Box>
          </Box>
        }
      ></CloudPopup>
      {/* isValidating */}
      <CloudPopup
        open={isValidating}
        width="498px"
        headerContent={
          <Box>
            <Box
              alignItems={'center'}
              display={'flex'}
              justifyContent={'center'}
              sx={{ mb: 4 }}
            >
              <img
                src={loaderImage}
                alt="loader"
                className={classes.loaderImage}
              />
            </Box>
            <CloudTypography textVariant="h6WithBold">
              Hold on tight, we’re validating Step {activeStep + 1}
            </CloudTypography>
          </Box>
        }
      ></CloudPopup>

      <CloudPopup
        open={isOtherAssetRunning}
        width="498px"
        bannerImage={wrongImage}
        onClose={() => setOpenTestCaseModel(false)}
        isCloseIconRequired={true}
        bannerColor={'#FFF0BC'}
        headerContent={
          <Box>
            <CloudTypography textVariant="h6">
              <b> {otherRunningAsset?.cloudLabAsset?.name} </b>is already
              running
            </CloudTypography>
            <CloudTypography
              textVariant="h9WithLight"
              color="#6B7580"
              sx={{ pt: 3 }}
            >
              Do you want to continue here or navigate to that asset?
            </CloudTypography>
            <Box
              alignItems={'center'}
              display={'flex'}
              justifyContent={'space-between'}
              sx={{ pt: 6 }}
            >
              <Button
                btnSize="small"
                onClick={() => {
                  stopExercise(otherRunningAsset?.slug);
                }}
                loadingText="Ending"
                color="error"
              >
                Stop & Continue here
              </Button>
              <Button
                btnSize="small"
                onClick={() => {
                  navigateTo(otherRunningAsset?.slug);
                }}
              >
                Navigate to that Asset
              </Button>
            </Box>
          </Box>
        }
      ></CloudPopup>
    </Box>
  );
};

// export const Details = withStyles(baseStyles)(DetilsComponent);

export default DetilsComponent;
