import { SvgIcon } from '@mui/material';
import React from 'react';
import { IconographyProps } from './iconography.interface';

const CheckCircleColorIcon = ({
  primaryColor,
  secondaryColor,
  sx,
  ...props
}: IconographyProps) => {
  const primaryDefault = '#33AB3F';
  const secondaryDefault = '#999999';
  return (
    <SvgIcon
      sx={{ fill: 'none', width: 20, height: 20, ...sx }}
      {...props}
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#clip0_40_121)">
        <path
          opacity="0.1"
          d="M10.335 18.67C14.9383 18.67 18.67 14.9383 18.67 10.335C18.67 5.73171 14.9383 2 10.335 2C5.73171 2 2 5.73171 2 10.335C2 14.9383 5.73171 18.67 10.335 18.67Z"
          fill={primaryColor || primaryDefault}
        />
        <path
          d="M8 11.0618L10.0618 12.78L13.8418 9"
          stroke={primaryColor || primaryDefault}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_40_121">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default CheckCircleColorIcon;
