// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { connect as socketIOConnect } from 'socket.io-client';
import { addMessage, connectSocket, sendMessageSuccess, sendMessageFailure, sendMessage, disconnectSocket, connectSocketSuccess } from './socket.slice';
import { onAddFileSuccess, onDeleteSuccess, onGetFileContentSuccess, onGetFilesSuccess, onTerminalDataSuccess, onMoveFileOrFolderSuccess, onCopyFileOrFolderSuccess, setEditorOpenFiles, onTerminalAdd, setEditorFile, setConfigs } from '../editor/editor.slice';



export interface ResponseGenerator {
    config?: any,
    data?: any,
    headers?: any,
    request?: any,
    status?: number,
    statusText?: string
    errors?: any
    socket: any,

}

function* handleSocketConnect() {

    try {

        const isConnected = yield select((state) => state.socket.isConnected);
        const socket = yield select((state) => state.socket.socket);

        if (!isConnected && !socket) {
            const newSocket = yield connectToSocketServer(); // Connect to socket server
            yield put(connectSocketSuccess(newSocket));

            while (true) {
                const message = yield call(receiveMessage, newSocket); // Listen for incoming messages
                yield saveMessageToRedux(message)
                // yield put(onGetFilesSuccess({ files: [] }));
            }
        }
    } catch (error) {
        // Handle any error that occurred during the connection process
        yield put(disconnectSocket());
    }
}

function* saveMessageToRedux(message) {
    const { action = null } = message;
    switch (action) {

        case 'GET_FILES_SUCCESS': {
            const { data } = message;
            yield put(onGetFilesSuccess({ files: data }))
            break;
        }
        case 'LOAD_MORE_FILES_SUCCESS': {
            const { data } = message;
            yield put(onGetFilesSuccess({ files: data, isUpdate: true }))
            break;
        }
        case 'GET_FILE_CONTENT_SUCCESS': {
            const { data } = message;
            yield put(onGetFileContentSuccess({ file: data }))
            break;
        }
        case 'ON_ADD_FILE_SUCCESS': {
            const { data } = message;
            yield put(onAddFileSuccess({ file: data }))
            break;
        }
        case 'ON_ADD_DIRECTORY_SUCCESS': {
            const { data } = message;
            yield put(onAddFileSuccess({ file: data }))
            break;
        }
        case 'ON_DELETE_SUCCESS': {
            const { data } = message;
            yield put(onDeleteSuccess({ file: data }))
            break;
        }
        case 'TERMINAL': {
            const { shellData, id } = message;
            yield put(onTerminalDataSuccess({ terminal: { value: shellData, id, date: new Date().getMilliseconds() } }))
            break;
        }
        case 'RENAME_FILE_OR_FOLDER_SUCCESS':
        case 'MOVE_FILE_OR_FOLDER_SUCCESS': {
            const { data } = message;
            yield put(onMoveFileOrFolderSuccess({ file: data }))
            break;
        }
        case 'MOVE_FILE_OR_FOLDER_ERROR': {

            break;
        }
        case 'COPY_FILE_OR_FOLDER_SUCCESS': {
            const { data } = message;
            yield put(onCopyFileOrFolderSuccess({ file: data }))
            break;
        }
        case 'COPY_FILE_OR_FOLDER_ERROR': {

            break;
        }
        case 'UPDATE_FILE_SUCCESS': {
            const { data } = message;
            yield put(setEditorFile({ ...data, handle: 'IS_DIRTY', handleValue: false }))
            break;
        }
        case 'GET_CONFIGS_SUCCESS': {
            const { data: { configs, rootPath } } = message;
            yield put(setConfigs({ configs: { ...configs, rootPath } }))





            // yield put(sendMessage({
            //     handler: 'initiate',
            //     message: {
            //         id: terminalId,
            //         // user: 'vasista',
            //         path: rootPath,
            //         isCreateLog: false,
            //     },
            // }))


            // yield put(sendMessage({
            //     handler: 'terminal',
            //     message: {
            //         id: terminalId,
            //         command: 'root@123\n',
            //         isCreateLog: false,
            //     },
            // }))

            // yield put(sendMessage({
            //     handler: 'terminal',
            //     message: {
            //         id: terminalId,
            //         command: `${terminals['terminal1'][1].commands.join(' && ')}\n`,
            //         isCreateLog: false,
            //     },
            // }))



            break;
        }

        default:
            yield put(addMessage(message));
            break;
    }

}



function connectToSocketServer() {
    let socketUrl;
    const hostName = window.location.hostname;
    if (hostName.includes('localhost')) {
        socketUrl = `http://${hostName}:9998`
    } else {
        socketUrl = `https://${hostName}:9998`
    }
    const socket = socketIOConnect(socketUrl, { transports: ['websocket'] }); // Replace with your Socket.io server URL

    return new Promise((resolve) => {
        socket.on('connect', () => {
            resolve(socket);
        });
    });
}

function receiveMessage(socket) {
    return new Promise((resolve) => {
        socket.on('data', (message) => {
            resolve(message);
        });
    });
}

function* handleSendMessage(action) {
    try {
        const { message, handler } = action.payload;
        const socket = yield select((state) => state.socket.socket);

        if (socket) {
            yield call(sendMessageToServer, socket, message, handler);
        }
    } catch (error) {
        // Handle any error that occurred during the message sending process
        yield put(sendMessageFailure(error));
    }
}

function sendMessageToServer(socket, message, handler = "message") {
    socket.emit(handler, message)
}

export function* socketSagaWatcher() {
    yield all([takeLatest(connectSocket.type, handleSocketConnect), takeLatest(sendMessage.type, handleSendMessage)]);
}


