import { SvgIcon } from '@mui/material';
import React from 'react';
import { IconographyProps } from './iconography.interface';

const CloseIcon = ({
  primaryColor,
  secondaryColor,
  sx,
  ...props
}: IconographyProps) => {
  const primaryDefault = '#2C3E50';
  const secondaryDefault = '#999999';
  return (
    <SvgIcon
      sx={{ fill: 'none', width: 32, height: 32, ...sx }}
      {...props}
      viewBox="0 0 32 32"
    >
      <g clipPath="url(#clip0_103_1405)">
        <path
          d="M9.4541 9.4541L22.545 22.545M22.545 9.4541L9.4541 22.545"
          stroke={primaryColor || primaryDefault}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_103_1405">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default CloseIcon;
