import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ::backdrop {
    background-color: white;
  }

  /* Slim scrolling start */
::-webkit-scrollbar {
  appearance: none;
  -webkit-appearance: none;
  width: 8px;
  border-radius: 0px;
  /* box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
}
::-webkit-scrollbar:hover {
  /* box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  transition: 0.6s;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-clip: content-box;
  background-color: #bebebe;
}

::-webkit-scrollbar-thumb:hover {
  /* box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  transition: 0.3s;
  background-clip: content-box;
  background-color: #8f8f8f;
}
  /*
    1. Use a more-intuitive box-sizing model.
  */
  *, *::before, *::after {
    box-sizing: border-box;
  } 
  /*
    2. Remove default margin
  */
  * {
    margin: 0;
  }
  /*
    3. Allow percentage-based heights in the application
  */
  html, body {
    height: 100%;
    margin: 0px;
      scroll-behavior: smooth;
  }
  /*
    Typographic tweaks!
    4. Add accessible line-height
    5. Improve text rendering
  */
  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }
  /*
    6. Improve media defaults
  */
  img, picture, video, canvas {
    display: block;
    max-width: 100%;
  }
  /*
    7. Remove built-in form typography styles
  */
  input, button, textarea, select {
    font: inherit;
  }
  /*
    8. Avoid text overflows
  */
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }
  /*
    9. Create a root stacking context
  */
  #root, #__next {
    height: 100%;
  }

  .react-flow__attribution {
    display: none !important;
    visibility: hidden;
    z-index: -1 !important;
  }

  .react-flow__renderer {
    z-index: 1!important
  }

  .clearfix:after {
    content:"";
    display:block;
    clear:both;
  }
  .PrivatePickersYear-root .Mui-selected {
    background-color: #6660E8;
    color: #fff;
  }
`;

export default GlobalStyle;
