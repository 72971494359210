import { COMMON_DICTIONARY } from './utils';

export interface LocalStorageProps {
  name: string;
  type: 'SAVE' | 'GET' | 'REMOVE';
  data?: any;
  isObject: boolean;
  encrypt?: boolean;
}

/**
 * method to pipeline all local storage access / read / writes
 * @param {LocalStorageProps} props
 */
export const localStore = (props: LocalStorageProps) => {
  const { type, isObject, name, data, encrypt = true } = props;

  const storeKey = name;
  switch (type) {
    case COMMON_DICTIONARY.SAVE:
      if (!data) {
        return;
      }
      // eslint-disable-next-line no-case-declarations
      const setData = isObject ? JSON.stringify(data) : data;
      return localStorage.setItem(storeKey, setData);

    case COMMON_DICTIONARY.GET:
      if (!localStorage.getItem(storeKey)) {
        return null;
      } else {
        const getData = localStorage.getItem(storeKey) || "";
        return isObject ? JSON.parse(getData) : getData;
      }

    case COMMON_DICTIONARY.REMOVE:
      return localStorage.removeItem(storeKey);

    default:
      return false;
  }
};

/**
 * Normal wrapper to clear the local-storage data
 */
export const clearLocalStore = () => {
  localStorage?.clear();
  sessionStorage.clear();
};
