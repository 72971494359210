import { combineReducers } from '@reduxjs/toolkit';

import {
  editorReducer,
  GET_EDITOR,
} from '@cloudlabs-frontend/features/editor';

import {
  cloudUserLabAssetReducer,
  GET_CLOUD_BASED_USER_LAB_ASSET,
} from '@cloudlabs-frontend/features/Landing';

import {
  SOCKET,
  socketReducer
} from '@cloudlabs-frontend/features/socket';

const rootReducer = combineReducers({
  [GET_CLOUD_BASED_USER_LAB_ASSET]: cloudUserLabAssetReducer,
  [GET_EDITOR]: editorReducer,
  [SOCKET]: socketReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
