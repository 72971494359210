import { IRAFormFlavourProps } from './ira-form-flavour.interfaces';

const withIRAFormFlavour = <T>(props: IRAFormFlavourProps & T): T => {
  const { withAsterisk, ...rest } = props;
  let updatedProps = rest as any;

  // tslint:disable-next-line
  if (withAsterisk) {
    if (updatedProps?.label) {
      updatedProps = {
        ...updatedProps,
        label: `${updatedProps?.label}*`
      };
    }

    // for ira-image-upload
    if (updatedProps?.infoText) {
      updatedProps = {
        ...updatedProps,
        infoText: `${updatedProps?.infoText}*`
      };
    }
  }
  return updatedProps as T;
};

export default withIRAFormFlavour;
