// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import theme from '@cloudlabs-frontend/common/theme';
import Box from '@mui/material/Box/Box';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import Button from 'libs/common/src/lib/components/core/button/button';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import Details from '../details';
import { ICloudBasedUserLabAsset } from './interface';
import {
  getCloudUserLabAsset,
  getPreviewUserLabAsset,
  selectCloudUserLabAsset,
} from './slice';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
/* eslint-disable-next-line */
// export interface CloudBasedProps {}

export const CloudBasedLanding = (props: any) => {
  const { subDomain, preview } = props;
  const dispatch = useDispatch();

  const cloudUserLabAsset = useSelector(
    selectCloudUserLabAsset
  ) as ICloudBasedUserLabAsset;

  const { cloudLabAsset = null } = cloudUserLabAsset || {};

  const { name } = cloudLabAsset || {};

  useEffect(() => {
    if (preview) {
      dispatch(getPreviewUserLabAsset({ where: { id: subDomain } }));
    } else {
      dispatch(getCloudUserLabAsset({ where: { slug: subDomain } }));
    }
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      {name && (
        <Details
          cloudUserLabAsset={cloudUserLabAsset}
          subDomain={subDomain}
          preview={preview}
        ></Details>
      )}
    </ThemeProvider>
  );
};

export default CloudBasedLanding;
