import { pxToRem } from '@cloudlabs-frontend/lib/utils/font-utils';
import { alpha, createTheme } from '@mui/material/styles';

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 1024,
    lg: 1200,
    xl: 1366,
    xxl: 1440,
  },
};

/**
 * Custom theme for this app
 */

export const theme: any = createTheme({
  breakpoints,
  palette: {
    common: {
      black: '#000000',
      white: '#ffffff',
    },
    background: {
      default: '#F6F3EC',
      paper: '#ffffff',
    },
    primary: {
      main: '#0A192B',
      dark: '#33AB3F',
      light: '#F6F3EC',
    },
    secondary: {
      main: '#6B7580',
      dark: '#F55757',
      light: '#FFFFFF',
    },
    gray: {
      main: '#E8E8EA',
      dark: '#91989F',
      light: '#F0F0EE',
      darker: '#787878',
      lighter: '#F5F5F5',
    },
    error: {
      main: '#D41900'
    },
    success: {
      main: '#F5D147',
    },
    text: {
      primary: '#0A192B',
      secondary: '#666666',
    },
    divider: '#eeeeee',
  },
  spacing: 4,
  typography: {
    fontFamily: '"Public Sans", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 700,
      fontSize: pxToRem(40),
      lineHeight: '150%',
      letterSpacing: 'normal',
    },

    h2: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 700,
      fontSize: pxToRem(30),
      lineHeight: '150%',
      letterSpacing: 'normal',
    },

    h3: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 700,
      fontSize: pxToRem(26),
      lineHeight: '150%',
      letterSpacing: 'normal',
    },

    h4: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(24),
      lineHeight: '150%',
      letterSpacing: 'normal',
    },

    h5: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 700,
      fontSize: pxToRem(22),
      lineHeight: '150%',
      letterSpacing: 'normal',
    },

    h6: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(20),
      lineHeight: '150%',
      letterSpacing: 'normal',
    },
    h7: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(18),
      lineHeight: '150%',
      letterSpacing: 'normal',
    },
    h8: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(16),
      lineHeight: '150%',
      letterSpacing: 'normal',
    },
    h9: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(14),
      lineHeight: '150%',
      letterSpacing: 'normal',
    },
    h10: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(13),
      lineHeight: '150%',
      letterSpacing: 'normal',
    },
    h11: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(12),
      lineHeight: '150%',
      letterSpacing: 'normal',
    },
    h12: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(10),
      lineHeight: '150%',
      letterSpacing: 'normal',
    },
    button: {
      fontFamily: '"Public Sans", sans-serif',
      letterSpacing: 'normal',
      fontStretch: 'normal',
      fontWeight: 600,
      fontSize: pxToRem(14),
      lineHeight: '150%',
    },
  },
  shape: {
    borderRadius: 4,
  },
  customShadow: {
    primary: `2px 2px 12px 0 ${alpha('#18274B', 0.15)}`,
    darker: `0 0 12px 0 ${alpha('#000000', 0.12)}`,
    lighter: `0 -4px 7px 0 ${alpha('#000000', 0.03)}`,
  },
  transitions: {
    duration: {
      enteringScreen: 500,
      leavingScreen: 500,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          [`@media (min-width:${breakpoints.values.lg}px)`]: {
            // 1200
            maxWidth: '1150px',
          },
          [`@media (min-width:${breakpoints.values.xl}px)`]: {
            // 1366
            maxWidth: '1280px',
          },
        },
        maxWidthXs: {
          '&.MuiContainer-maxWidthXs': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
        maxWidthSm: {
          '&.MuiContainer-maxWidthSm': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
        maxWidthMd: {
          '&.MuiContainer-maxWidthMd': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
        maxWidthLg: {
          '&.MuiContainer-maxWidthLg': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
        maxWidthXl: {
          '&.MuiContainer-maxWidthXl': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application 💣!
        disableTouchRipple: true,
        focusRipple: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontSize: pxToRem(12),
          letterSpacing: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          paddingLeft: 15,
          paddingRight: 15,
        },
        deleteIcon: {
          width: 16,
          height: 16,
          color: '#aaaaaa',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
          '&$disabled': {
            color: '#bababa',
          },
        },
        label: {
          fontSize: pxToRem(14),
          fontWeight: 400,
          lineHeight: 1.64,
          fontStretch: 'normal',
          letterSpacing: 'normal',
        },
        labelPlacementStart: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          width: 19,
          height: 19,
          color: '#000000',
          paddingRight: '20px',
          paddingLeft: '20px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        dense: {
          height: 64,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation4: {
          backgroundColor: '#ffffff',
          boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.06) !important',
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        onClick: (e) => e.stopPropagation(),
        BackdropProps: {
          sx: {
            pointerEvents: 'none',
          },
        },
      },
    },
  },
  prism: {
    loader: {
      strokeColor: '#33AB3F',
      strokeBackgroundColor: '#EEEEEE',
      pieColor: '#ffffff',
      pieBackgroundColor: '#33AB3F14',
    },
    card: {
      borderColor: '#F6F3EC',
      color: '#6B7580',
    },
    tabs: {
      color: {
        background: '#EEEBE5',
        expandNumber: '#6B7580',
        expandIconBackground: '#D8D4CE',
      },
    },
    button: {
      color: {
        backgroundColor: {
          tag: '#ECE9E2',
          navigation: '#F0F0EE',
        },
      },
      action: {
        hoverBackground: {
          primary: '#2E3B4B',
          secondary: '#D9DAD6',
          tag: '#D9D4C9',
          error: '#CD4F3E',
        },
        disabledBackground: {
          primary: '#CED1D5',
          secondary: '#9DA3AB',
          tertiary: '#9DA3AB',
          tag: '#F7F6F3',
        },
        disabledOpacity: 0.4,
        disabledVariantBackground: '#F3F3F3',
      },
      typography: {
        smaller: {
          fontSize: '12px',
          lineHeight: '14px',
          fontWeight: 500,
        },
        smallest: {
          fontSize: '12px',
          lineHeight: '14px',
          fontWeight: 400,
        },
        navigation: {
          fontFamily: '"Public Sans", sans-serif',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '16.45px',
        },
      },
    },
    tooltip: {
      color: {
        iconBackground: '#152D4A',
      },
    },
    tag: {
      filled: {
        color: '#0A192B',
        backgroundColor: '#F0F0EE',
      },
      outlined: {
        color: '#111111',
        backgroundColor: '#FFFFFF',
      },
      color: {
        blue: '#55BBF6',
        orange: '#F0983F',
        yellow: '#F5D147',
        pink: '#FDACCD',
        purple: '#CFBAFF',
      },
      action: {
        // combine this value with color above to create background color for indication tag
        indicationOpacity: 0.1,
      },
    },
    rating: {
      color: {
        awful: '#EE5120',
        bad: '#E29F51',
        okay: '#FEA800',
        good: '#FFD600',
        great: '#33C042',
        star: '#F3DC0C',
      },
    },
    stepper: {
      step: {
        color: {
          main: '#33C042',
          light: '#d6f2d9',
          contrastText: '#fff',
        },
      },
      connector: {
        color: '#33C042',
        opacity: 0.1,
      },
    },
    trophy: {
      color: {
        backgroundCircle: '#FFFFFF66',
        innerCircle: '#33AB3F',
        text: '#CCCCCC',
      },
    },
    performanceProgress: {
      color: {
        backgroundCircle: '#476B71',
      },
    },
    accordion: {
      root: {
        border: '1px solid #F6F3EC',
        borderRadius: '4px',
        boxShadow: 'none',
        backgroundColor: 'white',
      },
      title: {
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '21px',
      },
      description: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
      },
    },
    menuBar: {
      color: {
        activeDot: '#3DBC4A',
      },
    },
  },
});

export default theme;

/**
 * Module augmentation
 */
declare module '@mui/material/styles' {
  interface Theme {
    customShadow: {
      primary: string;
      darker: string;
      lighter: string;
    };
    prism: {
      loader: {
        strokeColor: string;
        strokeBackgroundColor: string;
        pieColor: string;
        pieBackgroundColor: string;
      };
      card: {
        borderColor: string;
        color: string;
      };
      tabs: {
        color: {
          background: string;
          expandNumber: string;
          expandIconBackground: string;
        };
      };
      button: {
        color: {
          backgroundColor: {
            tag: string;
            navigation: string;
          };
        };
        action: {
          hoverBackground: {
            primary: string;
            secondary: string;
            tag: string;
            error: string
          };
          disabledBackground: {
            primary: string;
            secondary: string;
            tertiary: string;
            tag: string;
          };
          disabledOpacity: number;
          disabledVariantBackground: string;
        };
        typography: {
          smaller: React.CSSProperties;
          smallest: React.CSSProperties;
          navigation: React.CSSProperties;
        };
      };
      tooltip: {
        color: {
          iconBackground: string;
        };
      };
      tag?: {
        filled?: {
          color?: string;
          backgroundColor?: string;
        };
        outlined?: {
          color?: string;
          backgroundColor?: string;
        };
        color?: {
          blue?: string;
          orange?: string;
          yellow?: string;
          pink?: string;
          purple?: string;
        };
        action?: {
          indicationOpacity?: number;
        };
      };
      rating: {
        color: {
          awful: string;
          bad: string;
          okay: string;
          good: string;
          great: string;
          star: string;
        };
      };
      trophy: {
        color: {
          backgroundCircle: string;
          innerCircle: string;
          text: string;
        };
      };
      performanceProgress: {
        color: {
          backgroundCircle: string;
        };
      };
      accordion: {
        root: React.CSSProperties;
        title: React.CSSProperties;
        description: React.CSSProperties;
      };
      menuBar: {
        color: {
          activeDot: string;
        };
      };
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    customShadow?: {
      primary?: string;
      darker?: string;
      lighter?: string;
    };
    prism?: {
      loader?: {
        strokeColor?: string;
        strokeBackgroundColor?: string;
        pieColor?: string;
        pieBackgroundColor?: string;
      };
      card: {
        borderColor: string;
        color: string;
      };
      tabs: {
        color: {
          background: string;
          expandNumber: string;
          expandIconBackground: string;
        };
      };
      button?: {
        color?: {
          backgroundColor?: {
            tag?: string;
            navigation?: string;
          };
        };
        action?: {
          hoverBackground?: {
            primary?: string;
            secondary?: string;
            tag?: string;
            error?: string
          };
          disabledBackground?: {
            primary?: string;
            secondary?: string;
            tertiary?: string;
            tag?: string;
          };
          disabledOpacity: number;
          disabledVariantBackground: string;
        };
        typography?: {
          smaller?: React.CSSProperties;
          smallest?: React.CSSProperties;
          navigation?: React.CSSProperties;
        };
      };
      tooltip?: {
        color?: {
          iconBackground?: string;
        };
      };
      tag?: {
        filled?: {
          color?: string;
          backgroundColor?: string;
        };
        outlined?: {
          color?: string;
          backgroundColor?: string;
        };
        color?: {
          blue?: string;
          orange?: string;
          yellow?: string;
          pink?: string;
          purple?: string;
        };
        action?: {
          indicationOpacity?: number;
        };
      };
      rating?: {
        color?: {
          awful?: string;
          bad?: string;
          okay?: string;
          good?: string;
          great?: string;
          star?: string;
        };
      };
      stepper?: {
        step?: {
          color?: {
            main?: string;
            light?: string;
            contrastText?: string;
          };
        };
        connector?: {
          color?: string;
          opacity?: number;
        };
      };
      trophy?: {
        color?: {
          backgroundCircle?: string;
          innerCircle?: string;
          text?: string;
        };
      };
      performanceProgress: {
        color: {
          backgroundCircle: string;
        };
      };
      accordion: {
        root: React.CSSProperties;
        title: React.CSSProperties;
        description: React.CSSProperties;
      };

      menuBar: {
        color: {
          activeDot: string;
        };
      };
    };
  }

  interface PaletteOptions {
    gray: PaletteOptions['primary'];
  }

  interface PaletteColor {
    darker?: string;
    lighter?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
    lighter?: string;
  }

  interface TypographyVariantsOptions {
    h7?: React.CSSProperties;
    h8?: React.CSSProperties;
    h9?: React.CSSProperties;
    h10?: React.CSSProperties;
    h11?: React.CSSProperties;
    h12?: React.CSSProperties;
    // h13?: React.CSSProperties;
    // body3?: React.CSSProperties;
    // body4?: React.CSSProperties;
    // body5?: React.CSSProperties;
    // body6?: React.CSSProperties;
    // body7?: React.CSSProperties;
    // body8?: React.CSSProperties;
    // body9?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h7: true;
    h8: true;
    h9: true;
    h10: true;
    h11: true;
    h12: true;
    // h13: true;
    // body3: true;
    // body4: true;
    // body5: true;
    // body6: true;
    // body7: true;
    // body8: true;
    // body9: true;
  }
}
