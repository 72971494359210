import GlobalStyle from '@cloudlabs-frontend/common/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'libs/common/src/lib/theme';
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Routes from './routes';
import Keycloak from 'keycloak-js';
import { localStore } from '@cloudlabs-frontend/lib/utils/localStore';
import Configuration from '@cloudlabs-frontend/lib/utils/config/config';

export function App() {
  const keycloak = Keycloak({
    url: Configuration.keycloakAuthUrl,
    realm: Configuration.keycloakRealm,
    clientId: 'cloudlabs-authoring-frontend',
  });

  const tokenLogger = (tokens: any) => {
    localStore({
      name: 'USER',
      type: 'SAVE',
      isObject: true,
      data: { token: tokens.token },
    });
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onTokens={tokenLogger}
      initOptions={{ onLoad: 'login-required' }}
    >
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <CssBaseline />
          <GlobalStyle />
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
