import MuiButton, { buttonClasses } from '@mui/material/Button';
import * as React from 'react';
import { styled } from '@mui/material/styles';

import { IButtonProps } from './button.interface';

export const ButtonRoot = (props: IButtonProps) => {
  const {
    label,
    btnVariant,
    btnSize,
    rounded,
    loading,
    loadingText,
    children,
    ...rest
  } = props;

  const renderContent = () => {
    if (loading) {
      return <span className="PrismButton-text">{loadingText}</span>;
    }

    if (label) {
      return <span className="PrismButton-text">{label}</span>;
    }

    return children;
  };

  return <MuiButton {...rest}>{renderContent()}</MuiButton>;
};

const Button = styled(ButtonRoot)(
  ({
    theme,
    btnVariant = 'primary',
    btnSize = 'medium',
    color = 'primary',
    rounded = false,
    loading = false,
    loadingText = 'loading',
  }) => ({
    ...theme.typography.button,
    textTransform: 'none',
    [`& .${buttonClasses.endIcon}`]: {
      marginLeft: theme.spacing(1),
      marginRight: 0,
    },
    [`& .${buttonClasses.startIcon}`]: {
      marginRight: theme.spacing(1),
      marginLeft: 0,
    },
    /**
     * Variant
     */
    ...(btnVariant === 'primary' && {
      backgroundColor:
        color === 'error'
          ? theme.palette.error.main
          : theme.palette.common.black,
      color: theme.palette.common.white,
    }),
    ...(btnVariant === 'secondary' && {
      backgroundColor: 'transparent',
      color: theme.palette.common.black,
      border: `1px solid ${theme.palette.common.black}`,
    }),
    ...(btnVariant === 'tertiary' && {
      backgroundColor: 'inherit',
      color: theme.palette.common.black,

      '& .PrismButton-text': {
        ...(btnVariant === 'tertiary' && {
          textDecoration: 'underline',
        }),
      },
    }),
    ...(btnVariant === 'tag' && {
      backgroundColor: theme.prism.button.color.backgroundColor.tag,
    }),
    /**
     * Size
     */
    ...(btnSize === 'large' && {
      padding: theme.spacing(4, 6),
      borderRadius: theme.shape.borderRadius,
    }),
    ...(btnSize === 'medium' && {
      padding: theme.spacing(3, 6),
      borderRadius: theme.shape.borderRadius,
    }),
    ...(btnSize === 'small' && {
      padding: theme.spacing(2, 3),
      borderRadius: theme.shape.borderRadius,
    }),
    ...(btnSize === 'smaller' && {
      padding: theme.spacing(2, 3),
      borderRadius: theme.shape.borderRadius,
      // ...theme.prism.button.typography.smaller,
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: 500,
    }),
    ...(btnSize === 'smallest' && {
      padding: theme.spacing(5 / 4, 2),
      borderRadius: theme.shape.borderRadius,
      // ...theme.prism.button.typography.smallest,
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: 400,
    }),
    ...(btnVariant === 'tag' && {
      fontWeight: theme.typography.fontWeightRegular,
      borderRadius: (theme.shape.borderRadius * 13) / 2,
    }),
    /**
     * Rounded
     */
    ...(rounded &&
      btnVariant !== 'tertiary' &&
      btnVariant !== 'tag' && {
        borderRadius: theme.shape.borderRadius * 7,
      }),
    /**
     * Action
     */
    '&:hover': {
      ...(btnVariant === 'primary' && {
        backgroundColor:
          color === 'error'
            ? theme.prism.button.action.hoverBackground.error
            : theme.prism.button.action.hoverBackground.primary,
      }),
      ...(btnVariant === 'secondary' && {
        backgroundColor: '#D9DAD6',
        // theme.prism.button.action.hoverBackground.secondary,
      }),
      ...(btnVariant === 'tertiary' && {
        backgroundColor: 'transparent',
      }),
      ...(btnVariant === 'tag' && {
        backgroundColor: theme.prism.button.action.hoverBackground.tag,
      }),
    },
    [`&.${buttonClasses.disabled}`]: {
      ...(btnVariant === 'primary' && {
        backgroundColor: theme.prism.button.action.disabledBackground.primary,
        color: theme.palette.common.white,
      }),
      ...(btnVariant === 'secondary' && {
        borderColor: '#9DA3AB',
        //  theme.prism.button.action.disabledBackground.secondary,
        color: '#9DA3AB',
        //  theme.prism.button.action.disabledBackground.secondary,
      }),
      ...(btnVariant === 'tertiary' && {
        borderColor: theme.prism.button.action.disabledBackground.tertiary,
      }),
      ...(btnVariant === 'tag' && {
        backgroundColor: theme.prism.button.action.disabledBackground.tag,
      }),
    },
    ...(loading && {
      pointerEvents: 'none',
    }),
  })
);

export default Button;
