import { SvgIcon } from '@mui/material';
import React from 'react';
import { IconographyProps } from './iconography.interface';

const WrongCircleRedLargeIcon = ({
  primaryColor,
  secondaryColor,
  sx,
  ...props
}: IconographyProps) => {
  const primaryDefault = '#F55757';
  const secondaryDefault = '#FEEFEF';
  return (
    <SvgIcon
      sx={{ fill: 'none', width: 20, height: 20, ...sx }}
      {...props}
      viewBox="0 0 30 30"
    >
      <circle
        cx="15"
        cy="15"
        r="15"
        fill={secondaryColor || secondaryDefault}
      />
      {/* <path d="M1.5 1.5L6.5 6.5M6.5 1.5L1.5 6.5" stroke={primaryColor || primaryDefault} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> */}
      <path
        d="M10 10L20 20M20 10L10 20"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={primaryColor || primaryDefault}
      />
    </SvgIcon>
  );
};

export default WrongCircleRedLargeIcon;
