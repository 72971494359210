import { getCurrentRole, keycloak, logoutKeycloak } from '@cloudlabs-frontend/utils/auth-utils';
import Configuration from '@cloudlabs-frontend/utils/configuration';
import { clearLocalStore, localStore } from '@cloudlabs-frontend/utils/localStore';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient as Client } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import ApolloLinkTimeout from 'apollo-link-timeout';

declare let newrelic: any;

const defaultOptions: any = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};
const DEFAULT_TIMEOUT = 15000000; // 15 second timeout

const timeoutLink = new ApolloLinkTimeout(DEFAULT_TIMEOUT);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (Configuration.environment !== 'prod' && graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }

  if (graphQLErrors?.[0]?.extensions?.['code'] === 'UNAUTHENTICATED') {
    const { origin, pathname } = window.location;
    const [, role] = pathname.split('/');
    if (graphQLErrors?.[0]?.message.toLowerCase().includes('invalid token')) {
      // clear all expired token and push user to login page
      logoutKeycloak(`${window.location.origin}/identity/login`);
      // clearLocalStore();
      // keycloak.logout({ redirectUri: `${origin}/identity/login` });

      alert('Session expired. Please login again.');
    }
  }
});

// set authorization header for all gql requests
const authLink = setContext((_, { headers }) => {
  const tokenData = localStore({
    name: 'USER',
    type: 'GET', isObject: true
  })
  const token = tokenData.token;

  return token
    ? {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`
      },
    }
    : {
      headers: {
        ...headers,
      },
    };
});

const url = (client) => {
  switch (client) {
    case 'preview':
      return Configuration.graphqlPreviewEndPoint
    default:
      return Configuration.graphqlEndPoint
  }
}

// composing links
const link = ApolloLink.from([
  timeoutLink,
  authLink,

  // Commented on 26-02-2020 5:30Pm as it causing login all time!
  errorLink,
  new HttpLink({
    uri: operation => {
      return url(operation.getContext().client);
    }
  }),
]);

export const apollo = new Client({
  link,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: defaultOptions,
});

export default apollo;
