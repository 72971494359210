import { all } from 'redux-saga/effects';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  cloudLabAssetSagaWatcher,
} from '@cloudlabs-frontend/features/Landing';

import {
  editorSagaWatcher,
} from '@cloudlabs-frontend/features/editor';

import {
  socketSagaWatcher,
} from '@cloudlabs-frontend/features/socket';

export default function* rootSaga() {
  yield all([
    cloudLabAssetSagaWatcher(),
    editorSagaWatcher(),
    socketSagaWatcher()
  ]);
}
