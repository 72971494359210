import FolderIcon from '@mui/icons-material/Folder';
import React from 'react';
import { getIconForFile } from 'vscode-icons-js';

import styles from './TypeIcon.module.scss';

type Props = {
  file: string;
  fileType?: string;
};

export const TypeIcon: React.FC<Props> = (props) => {
  if (props.file === 'DIRECTORY') {
    return <FolderIcon />;
  }

  if (props.file === 'SYMBOLIC_LINK') {
    return <FolderIcon />;
  }
  return (
    <img
      className={styles['img']}
      src={`/assets/fileicons/${getIconForFile(props.file)}`}
      alt="logo"
    />
  );
};
