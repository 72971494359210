import { clearLocalStore, localStore } from './localStore';
import Keycloak, { KeycloakInitOptions } from 'keycloak-js';
import Configuration from './config/config';
import { RoleType, User } from './interfaces';
import axios from "axios";

const CURRENT_ROLE_KEY = 'current-role';
const USER_KEY = 'user';
const CHAT_ID_KEY = 'chatId';
const DOUBT_FORM_SESSION_KEY = 'doubtFormSession';

export const PUBLIC_URL_FRAGMENTS = [
  '/feedback/',
  '/lti/',
  '/group-assessments/',
];

export const keycloakInItOptions: KeycloakInitOptions = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri:
    window.location.origin + '/assets/silent-check-sso.html',
  enableLogging: true,
};

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
// const keycloak = new Keycloak(keyCloakConfig);
export const keycloak = Keycloak({
  realm: Configuration?.keycloakRealm,
  // url: 'https://auth.kh3ira.com/auth/',
  url: Configuration?.keycloakAuthUrl,
  clientId: 'ira-frontend',
});

/**
 * @name removeCurrentRole
 * @experimental
 * @param role : role to remove in the storage
 * Note: will be in encrypted format (base64)
 *
 */
export const removeCurrentRole = (role: RoleType) => {
  localStore({
    name: CURRENT_ROLE_KEY,
    type: 'REMOVE',
    isObject: false,
    data: role,
  });
};

/**
 * @name getCurrentRole
 * return the decrypted current role from in storage
 * @returns role
 * @description defined for future scope -switching roles
 */
export const getCurrentRole = (): RoleType => {
  const currentRole = localStore({
    name: CURRENT_ROLE_KEY,
    type: 'GET',
    isObject: false,
  });
  return currentRole as RoleType;
};

/**
 * @name setCurrentRole
 * @experimental
 * @param role : role to save in the storage
 * Note: will be in encrypted format (base64)
 *
 */
export const setCurrentRole = (role: RoleType) => {
  localStore({
    name: CURRENT_ROLE_KEY,
    type: 'SAVE',
    isObject: false,
    data: role,
  });
};

/**
 * @method getCurrentRoleName : get current role name
 * @param role string
 * @returns current role name
 */
export const getCurrentRoleName = (role: string) => {
  let currentRole = '';
  switch (role) {
    case 'instructor':
      currentRole = 'Instructor';
      break;
    case 'mentor':
      currentRole = 'Mentor';
      break;
    case 'sme':
      currentRole = 'SME';
      break;
    case 'evaluator':
      currentRole = 'Evaluator';
      break;
    default:
      currentRole = 'Instructor';
  }
  return currentRole;
};

type GetUserReturnType = User & {
  username?: string;
  backgroundColor?: string;
  isSkipProductIntroduced?: boolean;
};

/**
 * @name getUser
 * return the decrypted current user from in storage
 * @returns User
 */
export const getUser = (): GetUserReturnType => {
  const user = localStore({
    name: USER_KEY,
    type: 'GET',
    isObject: true,
  });
  return user as GetUserReturnType;
};

/**
 * @name setUser
 * @describe stores the passed in user to the storage
 */
export const setUser = (user: User) => {
  localStore({
    name: USER_KEY,
    type: 'SAVE',
    isObject: true,
    data: user,
  });
};

/**
 * @name clearUser
 * @describe removes user in the storage
 */
export const clearUser = () => {
  localStore({
    name: USER_KEY,
    type: 'REMOVE',
    isObject: true,
  });
};

/**
 * @name getUserRoles
 * return the decrypted current user-roles from in storage
 * @returns {User['roles']} roles
 */
export const getUserRoles = (): User['roles'] => {
  const { roles } = getUser() || {};
  return (roles || []) as User['roles'];
};

/**
 * @name hasRole
 * @param {RoleType} role role to be verified among user roles
 * @returns {boolean} boolean defining the result
 */
export const hasRole = (verifyRole: RoleType): boolean => {
  const roles = getUserRoles() || [];
  return roles?.some(({ role: { name } }) => name === verifyRole);
};

/**
 * @name isDefaultRole
 * @param {RoleType} role role to be verified for default
 * @returns {boolean} boolean defining the result
 */
export const isDefaultRole = (verifyRole: RoleType): boolean => {
  const roles = getUserRoles() || [];
  return roles?.some(
    ({ role: { name }, isDefaultRole }) => name === verifyRole && isDefaultRole
  );
};

/**
 * @name getChatId
 * return the decrypted current user chat id from in storage
 * @returns string
 */
export const getChatId = (): string => {
  const chatId = localStore({
    name: CHAT_ID_KEY,
    type: 'GET',
    isObject: false,
  });
  return chatId as string;
};

/**
 * @name setChatId
 * @describe stores the passed in chat id to the storage
 */
export const setChatId = (chatId: string) => {
  localStore({
    name: CHAT_ID_KEY,
    type: 'SAVE',
    isObject: false,
    data: chatId,
  });
};

/**
 * @name clearChatId
 * @describe clears chat id in the storage
 */
export const clearChatId = () => {
  localStore({
    name: CHAT_ID_KEY,
    type: 'REMOVE',
    isObject: false,
  });
};

/**
 * @name getIsSwitchRole
 * return the boolean value true/false from in storage
 * @returns boolean
 * @description checkpoint to verify if navigated from switch role
 */
export const getIsSwitchRole = () => {
  const isSwitchRole = localStore({
    name: 'isSwitchRole',
    type: 'GET',
    isObject: false,
  });
  return Boolean(isSwitchRole);
};

/**
 * @name setIsSwitchRole
 * set the boolean value true/false from in storage
 * @description set the boolean value true in storage when navigating through switch role
 */
export const setIsSwitchRole = (value: boolean) => {
  localStore({
    name: 'isSwitchRole',
    type: 'SAVE',
    isObject: false,
    data: Boolean(value),
  });
};

/**
 * @name removeIsSwitchRole
 */
export const removeIsSwitchRole = () => {
  localStore({
    name: 'isSwitchRole',
    type: 'REMOVE',
    isObject: false,
  });
};

/**
 * @returns timezone for user
 */
export const getUserTimezone = () => {
  let timezone =
    getUser()?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

  // due to error in intl
  if (timezone === 'Asia/Calcutta') {
    timezone = 'Asia/Kolkata';
  }
  return timezone;
};

/**
 * @name getIsRedirect
 * @returns boolean
 */
export const getIsRedirect = () => {
  const isRedirect = localStore({
    name: 'isRedirect',
    type: 'GET',
    isObject: false,
  });
  return Boolean(isRedirect);
};

/**
 * @name removeIsRedirect
 */
export const removeIsRedirect = () => {
  localStore({
    name: 'isRedirect',
    type: 'REMOVE',
    isObject: false,
  });
};

/**
 * @name getLocalTimezone
 * @returns timezone in string eg;'Asia/Kolkata'
 */
export const getLocalTimezone = () => {
  return (
    getUser()?.timezone ??
    (Intl.DateTimeFormat().resolvedOptions().timeZone as string)
  );
};

type GetDoubtFormSessionDetailsType = {
  sessionStartsAt?: string;
  sessionEndsAt?: string;
  isOpenDoubtForm?: boolean;
};

/**
 * @name getDoubtFormSessionDetails
 * return the decrypted current session details from storage
 * @returns Session
 */
export const getDoubtFormSessionDetails =
  (): GetDoubtFormSessionDetailsType => {
    const doubtFormSession = localStore({
      name: DOUBT_FORM_SESSION_KEY,
      type: 'GET',
      isObject: true,
    });
    return doubtFormSession;
  };

/**
 * @name setAdditionalSessionDetails
 * @describe stores the passed in session to the storage
 */
export const setDoubtFormSessionDetails = (
  doubtFormSession: GetDoubtFormSessionDetailsType
) => {
  localStore({
    name: DOUBT_FORM_SESSION_KEY,
    type: 'SAVE',
    isObject: true,
    data: doubtFormSession as GetDoubtFormSessionDetailsType,
  });
};

/**
 * @name clearDoubtFormSessionDetails
 * @describe removes session in the storage
 */
export const clearDoubtFormSessionDetails = () => {
  localStore({
    name: DOUBT_FORM_SESSION_KEY,
    type: 'REMOVE',
    isObject: true,
  });
};



export const logoutKeycloak = (redirectUrl: string) => {
  // console.log(keycloak?.createLogoutUrl())
  const token = keycloak?.authenticated && keycloak?.token;
  const refreshToken = keycloak?.authenticated && keycloak?.refreshToken;


  clearLocalStore();
  keycloak?.clearToken();
  window.location.href = `${Configuration.loginUrl}?type=login&redirect_uri=${redirectUrl}`;

  // return axios({
  //   method: 'post',
  //   url: keycloak?.createLogoutUrl().split('?')[0],
  //   headers: {
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //     ...(keycloak.token && { Authorization: `Bearer ${token}` })
  //   },
  //   data: new URLSearchParams({
  //     client_id: 'ira-frontend',
  //     refresh_token: refreshToken || ""
  //   }),
  //   withCredentials: true,
  // })
  //   .then((response: any) => {
  //     if (response.status === 204) {
  //       clearLocalStore();
  //       keycloak?.clearToken();
  //       window.location.href = `${Configuration.loginUrl}?type=login&redirect_uri=${redirectUrl}`;
  //       return true;
  //     } else {
  //       clearLocalStore();
  //       window.location.href = `${Configuration.loginUrl}?type=login&redirect_uri=${redirectUrl}`;
  //       return false;
  //     }
  //   })
  //   .catch((error: any) => {
  //     console.error('Error during session check:', error);
  //     return false;
  //   });
}
