import { SvgIcon } from '@mui/material';
import React from 'react';
import { IconographyProps } from './iconography.interface';

const ExpandMoreIcon = ({ primaryColor, sx, ...props }: IconographyProps) => {
  const primaryDefault = '#0A192B';
  return (
    <SvgIcon
      sx={{ fill: 'none', width: 23, height: 24, ...sx }}
      {...props}
      viewBox="0 0 23 24"
    >
      <path
        d="M6 9.5L11.75 15.25L17.5 9.5"
        stroke={primaryColor || primaryDefault}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ExpandMoreIcon;
