// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { CloseIcon } from '@cloudlabs-frontend/components/core/Iconography';
import { Box, Tab, Tabs } from '@mui/material';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import CloudTypography from 'libs/common/src/lib/components/core/cloud-typography/cloud-typography';
import { createRef, memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { FitAddon } from 'xterm-addon-fit';
import { XTerm } from 'xterm-for-react';

import { getSocketState, sendMessage } from '../../socket/socket.slice';
import {
  onTerminalAdd,
  onTerminalClose,
  selectConfigs,
  selectTerminals,
  selectTerminalTabs,
} from '../editor.slice';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
const useStyles = makeStyles()(() => {
  return {
    terminalTab: {
      minHeight: '25px',
      '& .MuiButtonBase-root': {
        minHeight: '25px',
        padding: '8px 16px',
        color: '#fff',
        opacity: '0.5',
        fontWeight: 400,
        '&.Mui-selected': {
          color: '#fff',
          opacity: '1',
        },
      },
      '& .MuiTabs-indicator': {
        height: '1px',
        backgroundColor: '#fff',
      },
    },
  };
});

type TerminalProps = {
  id?: number | string;
  terminalHeight?: number;
};

export function Terminal(props: TerminalProps) {
  const { terminalHeight } = props;
  const { classes } = useStyles();
  const xtermRef = useRef([]);
  // xtermRef.current = [];
  const parentRef = useRef(null);
  const fitAddon = new FitAddon();
  const dispatch = useDispatch();
  const terminals = useSelector(selectTerminals);
  const configs = useSelector(selectConfigs);

  const terminalTabs = useSelector(selectTerminalTabs);
  const socket = useSelector(getSocketState);
  const [activeTerminal, setActiveTerminal] = useState<number>(1);

  // const addToRefs: (el) => void = (el) => {
  //   if (el && !xtermRef.current.includes(el)) {
  //     xtermRef.current.push(el);
  //   }
  // };

  const handleKeyPress = (ev, terminal) => {
    if (ev.key === 'Backspace') {
      //   xtermRef.current.terminal.write('\b \b');
      dispatch(
        sendMessage({
          handler: 'terminal',
          message: {
            ...terminal,
            command: '\b \b',
            isCreateLog: false,
          },
        })
      );
      return;
    }
    if (ev.key === 'ArrowLeft') {
      //   xtermRef.current.terminal.write('\x1b[D');
      dispatch(
        sendMessage({
          handler: 'terminal',
          message: {
            ...terminal,
            command: '\x1b[D',
            isCreateLog: false,
          },
        })
      );
      return;
    }

    if (ev.key === 'ArrowRight') {
      //   xtermRef.current.terminal.write('\x1b[C');
      dispatch(
        sendMessage({
          handler: 'terminal',
          message: {
            ...terminal,
            command: '\x1b[C',
            isCreateLog: false,
          },
        })
      );
      return;
    }
    dispatch(
      sendMessage({
        handler: 'terminal',
        message: {
          ...terminal,
          command: ev.key,
          isCreateLog: false,
        },
      })
    );

    // xtermRef[activeTerminal].terminal.write(ev.key);
  };

  const handleTerminalChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setActiveTerminal(newValue);
  };

  const fitTerminal = (terminalId) => {
    // alert(1);
    const container = xtermRef[terminalId];
    if (container) {
      const fontSize = 14;

      const parent = parentRef.current;

      const parentWidth = parent.offsetWidth;
      const parentHeight = parent.offsetHeight;

      const rows = Math.floor(parentHeight / fontSize);

      const columns = Math.floor(
        parentWidth /
          (container.terminal._core._renderService.dimensions.actualCellWidth ||
            17)
      );
      let updatedRows;
      if (rows >= 24) {
        updatedRows = rows - 8;
      } else if (rows >= 20) {
        updatedRows = rows - 6;
      } else if (rows >= 10) {
        updatedRows = rows - 6;
      } else {
        updatedRows = rows - 4;
      }

      container.terminal.resize(columns, updatedRows);

      container.current &&
        container.current.terminal._addonManager._addons[0].instance &&
        fitAddon &&
        fitAddon.fit();
    }
  };

  const handleTerminalClose = (terminal) => {
    dispatch(
      onTerminalClose({
        terminal,
      })
    );
  };

  const randomInt = () => {
    const min = 10000000; // Smallest 8-digit number
    const max = 99999999; // Largest 8-digit number

    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber;
  };

  useEffect(() => {
    terminals.forEach((terminal) => {
      if (terminal?.isNew) {
        xtermRef[terminal.id] &&
          xtermRef[terminal.id].terminal.write(terminal?.value);

        // fitTerminal(terminal.id);
      }
    });
  }, [terminals]);

  useEffect(() => {
    // const terminalData = terminals.find(
    //   (terminal) => terminal.id === activeTerminal
    // );
    // console.log(activeTerminal);
    // console.log(terminalData);

    xtermRef.current = terminalTabs.map(
      (element, i) => xtermRef.current[element.id] ?? createRef()
    );

    const latestTerminal = terminalTabs[terminalTabs.length - 1];

    setActiveTerminal(latestTerminal?.id);
    if (latestTerminal?.id) {
      fitTerminal(latestTerminal.id);
    }
  }, [terminalTabs]);

  useEffect(() => {
    fitTerminal(activeTerminal);
  }, [terminalHeight]);

  useEffect(() => {
    // xtermRef[activeTerminal] && xtermRef[activeTerminal].terminal.clear();
    // const terminalData = terminals.find(
    //   (terminal) => terminal.id === activeTerminal
    // );
    // xtermRef[activeTerminal] &&
    //   xtermRef[activeTerminal].terminal.writeln(terminalData.value);
    // fitTerminal();
    fitTerminal(activeTerminal);
  }, [xtermRef[activeTerminal]]);

  useEffect(() => {
    // if (socket?.isConnected) {
    //   dispatch(
    //     onTerminalAdd({
    //       terminal: { id: 1, value: '', active: true },
    //     })
    //   );
    //   dispatch(
    //     onTerminalAdd({
    //       terminal: { id: 2, value: '', active: false },
    //     })
    //   );
    //   dispatch(
    //     sendMessage({
    //       handler: 'initiate',
    //       message: {
    //         id: 1,
    //         user: 'vasista',
    //         path: '/2.2.1-HO',
    //         isCreateLog: false,
    //       },
    //     })
    //   );
    //   dispatch(
    //     sendMessage({
    //       handler: 'initiate',
    //       message: {
    //         id: 2,
    //         user: 'vasista',
    //         path: '/2.2.1-HO',
    //         isCreateLog: false,
    //       },
    //     })
    //   );
    //   setActiveTerminal(1);
    // }
  }, [dispatch, socket?.isConnected]);

  useEffect(() => {
    const { terminals = {}, rootPath } = configs;
    if (Object.keys(terminals).length > 0) {
      const objectLength = Object.keys(terminals).length;
      for (const i of Array.from(Array(objectLength), (_, i) => i + 1)) {
        const name = terminals[`terminal${i}`][0].name;
        const commands = terminals[`terminal${i}`][1].commands;
        const terminalId = randomInt();
        const customTerminalId = `terminal${i}`;
        dispatch(
          onTerminalAdd({
            terminal: {
              id: terminalId,
              terminalId: customTerminalId,
              value: '',
              name,
              commands,
              active: true,
            },
          })
        );

        dispatch(
          sendMessage({
            handler: 'initiate',
            message: {
              id: terminalId,
              user: 'vasista',
              path: rootPath,
              isCreateLog: false,
            },
          })
        );

        dispatch(
          sendMessage({
            handler: 'terminal',
            message: {
              id: terminalId,
              command: 'root@123\n',
              isCreateLog: false,
            },
          })
        );

        dispatch(
          sendMessage({
            handler: 'terminal',
            message: {
              id: terminalId,
              command: `${terminals['terminal1'][1].commands.join(' && ')}\n`,
              isCreateLog: false,
            },
          })
        );
      }
      // if (Object.keys(objectCLone).length > 0) {
      //   await this.delay(2000)
      //   await this._openCommands(objectCLone, enableRun);
      // }
    }
  }, [configs]);

  return (
    <Box bgcolor={'#252526'}>
      <Box
        sx={{
          flexGrow: 1,
          maxWidth: { xs: 320, sm: '100%' },
        }}
      >
        {activeTerminal && (
          <Tabs
            value={activeTerminal}
            onChange={handleTerminalChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            className={classes.terminalTab}
          >
            {terminalTabs &&
              terminalTabs.map((terminal, index) => (
                <Tab
                  label={
                    <CloudTypography
                      textVariant="h11"
                      color="#6B7580"
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      Terminal {index + 1}
                      <CloseIcon
                        sx={{ height: '20px', paddingLeft: '15px' }}
                        onClick={() => handleTerminalClose(terminal)}
                      />
                    </CloudTypography>
                  }
                  value={terminal.id}
                  key={terminal.id}
                />
              ))}
          </Tabs>
        )}
      </Box>

      <Box ref={parentRef} height={terminalHeight} padding={3}>
        {terminalTabs &&
          terminalTabs.map((terminal, index) => (
            <Box
              key={terminal.id}
              id={terminal.id}
              sx={{
                display: terminal.id === activeTerminal ? 'block' : 'none',
              }}
            >
              <XTerm
                ref={(ref) => {
                  xtermRef[terminal.id] = ref;
                }}
                key={`${terminal.id}`}
                options={{
                  fontSize: 14,
                  theme: { background: '#252526' },
                }}
                onKey={(event) => handleKeyPress(event, terminal)}
                addons={[fitAddon]}
              ></XTerm>
            </Box>
          ))}
      </Box>
    </Box>
  );
}

export default Terminal;
